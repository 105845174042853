import React, { ReactNode } from 'react';
import { Color } from '../../theme';
import GridBlock from './GridBlock';
import { ThreeBlockSection } from '../../@types/types';

type Props = {
  content: ThreeBlockSection;
  colors: Color[];
  FirstButtons?: ReactNode;
  SecondButtons?: ReactNode;
  ThirdButtons?: ReactNode;
};

export default function ThreeSquareSection({
  content,
  colors,
  FirstButtons,
  SecondButtons,
  ThirdButtons,
}: Props) {
  const {
    FirstSectionTitle,
    FirstSectionText,
    FirstSectionPicture,
    SecondSectionTitle,
    SecondSectionText,
    SecondSectionPicture,
    ThirdSectionTitle,
    ThirdSectionText,
    ThirdSectionPicture,
  } = content;

  return (
    <>
      <GridBlock
        title={FirstSectionTitle}
        content={FirstSectionText}
        picture={FirstSectionPicture}
        boxColor={colors[0]}
        buttons={FirstButtons}
      />
      <GridBlock
        title={SecondSectionTitle}
        content={SecondSectionText}
        picture={SecondSectionPicture}
        boxColor={colors[1]}
        buttons={SecondButtons}
        reverse
      />
      <GridBlock
        title={ThirdSectionTitle}
        content={ThirdSectionText}
        picture={ThirdSectionPicture}
        boxColor={colors[2]}
        buttons={ThirdButtons}
      />
    </>
  );
}
