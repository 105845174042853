import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { IoIosArrowBack } from 'react-icons/io';
import { Text, Spacing, Icon, Stack, devices } from '../common';
import { PageTopSection } from '../../@types/types';
import { StrongUnderline } from '../SVG';

type Props = {
  topSection: PageTopSection;
  image: string;
};

const HeroSection = ({
  topSection: { PageTitle, TopSectionText },
  image,
}: Props) => {
  return (
    <Container>
      <NavLink to="/blogi">
        <Stack axis="x" spacing="medium" align="center">
          <Icon icon={IoIosArrowBack as any} size={24} color="black" />
          <BlogLable variant="body-s-semi-bold" color="black">
            BLOGI
          </BlogLable>
        </Stack>
      </NavLink>
      <Spacing axis="y" amount="xxxlarge" />

      <Title variant="title-1" color="black" align="center">
        {PageTitle}
      </Title>

      <SVG>
        <StrongUnderline width={235} />
      </SVG>

      <Image src={image} alt="" />

      {TopSectionText && (
        <>
          <Spacing amount={'medium'} axis="y" />
          <Text variant="subtitle" color="black">
            {TopSectionText}
          </Text>
        </>
      )}
    </Container>
  );
};

export default HeroSection;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: top;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 80px 0px 16px;
`;

const BlogLable = styled(Text)`
  letter-spacing: 2px;
`;

const SVG = styled.div`
  width: max-content;
  margin: ${(props) => props.theme.spacing.default} auto;
`;

const Image = styled.img`
  width: 100%;

  @media ${devices.laptopM} {
    width: 90%;
    margin: auto;
  }
`;

const NavLink = styled(Link)`
  margin: auto;
  width: max-content;
`;

const Title = styled(Text)`
  @media ${devices.mobileXL} {
    width: 90%;
    margin: 0px auto;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }
`;
