import '@reach/skip-nav/styles.css';
import React from 'react';
import styled from '@emotion/styled';
import { SkipNavLink, SkipNavContent } from '@reach/skip-nav';

import Header from './Header';
import Footer from './Footer';
import { Text, devices } from './common';

const Page: React.FC<{ location: Location }> = ({ children }) => {
  return (
    <>
      <link rel="stylesheet" href="https://use.typekit.net/szt1mnd.css"></link>

      <SkipNavLink>
        <Text variant="body">Siirry sisältöön</Text>
      </SkipNavLink>

      <PageWrapper>
        <Header />

        <Main>
          <SkipNavContent />
          {children}
        </Main>

        <Footer
          address={[
            {
              street: 'Kumpulantie 7 A',
              postalCode: '00520',
              city: 'Helsinki',
            },
          ]}
          services={[
            {
              name: 'Lasten ja nuorten psykiatria',
              slug: 'lasten-ja-nuorten-psykiatria',
            },
            {
              name: 'Lasten ja nuorten psykologipalvelut',
              slug: 'lasten-ja-nuorten-psykologipalvelut',
            },
            {
              name: 'Psykoterapia',
              slug: 'psykoterapia',
            },
          ]}
        />
      </PageWrapper>
    </>
  );
};

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
`;

const Main = styled.main`
  flex: 1 0 auto;
  /* min-height: 100vh; */
  margin-top: 100px;

  @media ${devices.laptopXS} {
    margin-top: 75px;
  }
`;

export default Page;
