import './src/index.css';
import React from 'react';
import Page from './src/components/Page';
import Providers from './src/components/Providers';

export const wrapRootElement = ({ element }) => {
  return <Providers>{element}</Providers>;
};

export const wrapPageElement = ({ element, props }) => {
  return <Page {...props}>{element}</Page>;
};

export const onRouteUpdate = ({ location }) => {
  const SHOULD_TRACK =
    process.env.NODE_ENV === 'production' &&
    window.location.host === 'aatosklinikka.fi';

  if (SHOULD_TRACK) {
    if (window.ga) {
      window.ga('set', 'page', location.pathname);
      window.ga('send', 'pageview');
    }
    if (window.dataLayer) {
      window.dataLayer.push({ event: 'pageview', pagePath: location.pathname });
    }
    if (window.fbq) {
      window.fbq('track', 'PageView');
    }
  }
};
