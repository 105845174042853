import React from 'react';
import type { SVGAttributes } from 'react';
import type { IconType } from 'react-icons';

import type { Color } from '../../theme';

type Props = SVGAttributes<any> & {
  icon: IconType;
  color: Color;
  size: 'small' | 'normal' | 'large' | number;
};

export default function Icon({
  icon: IconComponent,
  size,
  color,
  ...rest
}: Props) {
  return <IconComponent {...rest} color={color} size={size} />;
}
