import React from 'react';

const months = [
  'tammikuuta',
  'helmikuuta',
  'maaliskuuta',
  'huhtikuuta',
  'toukokuuta',
  'kesäkuuta',
  'heinäkuuta',
  'elokuuta',
  'syyskuuta',
  'lokakuuta',
  'marraskuuta',
  'joulukuuta',
];

export const formatDate = (date: string) => {
  const d = new Date(date);
  return `${d.getDate()}. ${months[d.getMonth()]} ${d.getFullYear()}`;
};

export const getYear = (date: string) => new Date(date).getFullYear();

export const noop = () => {}; // eslint-disable-line

export const range = (num: number) => Array.from({ length: num }, (x, i) => i);

export const sortBy = (arr: any[], field: string) => {
  return [...arr].sort((a, b) => {
    if (a[field] < b[field]) return -1;
    if (a[field] > b[field]) return 1;
    return 0;
  });
};

export const useOnClickOutside = (ref: any, handler: any) => {
  React.useEffect(() => {
    const listener = (event: any) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};
