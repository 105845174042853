import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { Content, devices } from '../common';
import { FirstPage, PreviousPage, NextPage, LastPage } from '../SVG';

type Props = {
  context: any; // TODO: UPDATE TYPE
};

const Pagination = ({ context }: Props) => {
  const { currentpage, numPages } = context;
  const isFirst = currentpage === 1;
  const isLast = currentpage === numPages;
  const prevPage = currentpage - 1 === 1 ? '' : (currentpage - 1).toString();
  const nextPage = (currentpage + 1).toString();

  //   REFERENCE: Adding the dots to the pagination came from this source:
  //   https://gist.github.com/kottenator/9d936eb3e4e3c3e02598

  const delta = 2;
  const left = currentpage - delta;
  const right = currentpage + delta + 1;
  const range = [];
  const rangeWithDots = [];
  let l;

  for (let i = 1; i <= numPages; i++) {
    if (i === 1 || i === numPages || (i >= left && i < right)) {
      range.push(i);
    }
  }

  for (const i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1);
      } else if (i - l !== 1) {
        rangeWithDots.push('...');
      }
    }
    rangeWithDots.push(i);
    l = i;
  }

  return (
    <Content>
      <Container>
        {!isFirst ? (
          <>
            <Link to={`/blogi/`}>
              <FirstPage isActive={false} />
            </Link>
            <Link to={`/blogi/${prevPage}`} rel={'prev'}>
              <PreviousPage isActive={false} />
            </Link>
          </>
        ) : (
          <>
            <FirstPage isActive={false} />
            <PreviousPage isActive={false} />
          </>
        )}

        {rangeWithDots.map((item: any) => (
          <PageItem key={item}>
            {item === '...' ? (
              <Dots>{item}</Dots>
            ) : (
              <PageLink
                to={`/blogi/${item === 1 ? '' : item}`}
                number={item}
                currentpage={currentpage}
              >
                {item}
              </PageLink>
            )}
          </PageItem>
        ))}

        {!isLast ? (
          <>
            <Link to={`/blogi/${nextPage}`} rel={'next'}>
              <NextPage isActive={false} />
            </Link>
            <Link to={`/blogi/${numPages}`} rel={'next'}>
              <LastPage isActive={false} />
            </Link>
          </>
        ) : (
          <>
            <NextPage isActive={false} />
            <LastPage isActive={false} />
          </>
        )}
      </Container>
    </Content>
  );
};

export default Pagination;

const Container = styled.ul`
  display: flex;
  flexwrap: wrap;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding-bottom: ${(props) => props.theme.spacing.xxxlarge};
  width: fit-content;
  margin: auto;
  padding-top: ${(props) => props.theme.spacing.default};

  @media ${devices.mobileXL} {
    width: 100%;
  }
`;

const PageLink = styled(Link)<{ number: any; currentpage: string }>`
  padding: rhythm(1 / 4);
  text-decoration: none;
  color: ${(props) =>
    props.number === props.currentpage
      ? props.theme.colors.black
      : props.theme.colors.inActiveGrey};
  font-weight: ${(props) => props.number === props.currentpage && 'bold'};
`;

const PageItem = styled.li`
  margin: ${(props) => props.theme.spacing.default};
`;

const Dots = styled.span`
  color: ${(props) => props.theme.colors.inActiveGrey};
`;
