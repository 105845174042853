import React from 'react';
import { graphql } from 'gatsby';
import VisuallyHidden from '@reach/visually-hidden';
import { SEO } from '../components/common';
import { PsychotherapiesPage, Psychotherapy } from '../@types/types';
import HeroSection from '../components/HeroSection';
import TextAccordion from '../components/Accordion/TextAccordion';

type Props = {
  data: {
    page: PsychotherapiesPage;
    psychotherapies: { nodes: Psychotherapy[] };
  };
  location: Location;
};

const Psychotherapies = ({ data, location }: Props) => {
  const {
    page: { MetaData, PageTopSection },
    psychotherapies: { nodes },
  } = data;

  return (
    <>
      <SEO
        pathname={location.pathname}
        title={MetaData.MetaTitle}
        description={MetaData.MetaDescription}
        keywords={MetaData.metaKeywords}
      />

      <HeroSection topSection={PageTopSection} />

      {nodes.map((psy, index) => (
        <TextAccordion
          key={index}
          order={index}
          header={psy.Title}
          content={psy.Description}
          targetUrl="/asiantuntijat"
        />
      ))}

      <VisuallyHidden>
        <TextAccordion
          order={11}
          header="Fake title"
          content="Fake description"
          targetUrl="/asiantuntijat"
          hidden
        />
      </VisuallyHidden>
    </>
  );
};

export default Psychotherapies;

export const expertQuery = graphql`
  query PsycotherapyPageQuery {
    page: strapiPsykoterapia {
      PageTopSection {
        PageTitle
        TopSectionText
      }
      MetaData {
        MetaDescription
        MetaTitle
        metaKeywords
      }
    }
    psychotherapies: allStrapiPsycotherapy {
      nodes {
        Description
        Title
        strapiId
      }
    }
  }
`;
