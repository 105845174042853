import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { SEO, devices } from '../components/common';
import HeroSection from '../components/HeroSection';
import Accordion from '../components/Accordion';
import { QaPage } from '../@types/types';
import { MAX_CONTENT_WIDTH } from '../constants';

type Props = {
  data: { page: QaPage; arrow: { publicURL: string } };
  location: Location;
};

const Qa = ({ data, location }: Props) => {
  const {
    page: { UkkItem, PageTopSection, MetaData },
    arrow,
  } = data;

  return (
    <>
      <SEO
        pathname={location.pathname}
        title={MetaData.MetaTitle}
        description={MetaData.MetaDescription}
        keywords={MetaData.metaKeywords}
      />

      <HeroSection topSection={PageTopSection} image={arrow.publicURL} />
      <Container>
        <Content>
          {UkkItem.map((item) => (
            <Accordion
              key={item.Header}
              header={item.Header}
              text={item.Content}
            />
          ))}
        </Content>
      </Container>
    </>
  );
};

export default Qa;

export const qaQuery = graphql`
  query QaPageQuery {
    page: strapiUkk {
      UkkItem {
        Content
        Header
      }
      PageTopSection {
        PageTitle
        TopSectionText
      }
      MetaData {
        MetaDescription
        MetaTitle
        metaKeywords
      }
    }
    arrow: file(name: { eq: "blue-arrow" }) {
      publicURL
    }
  }
`;

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.lightBlue};
  padding: ${(props) => props.theme.spacing.xxlarge};

  @media ${devices.tablet} {
    padding: ${(props) => props.theme.spacing.xlarge} 0px;
  }
`;

const Content = styled.div`
  width: 100%;
  max-width: ${MAX_CONTENT_WIDTH};
  margin-left: auto;
  margin-right: auto;

  @media ${devices.laptopL} {
    width: 90% !important;
  }
`;
