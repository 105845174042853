import React from 'react';
import styled from '@emotion/styled';
import { Stack, Text, Spacing, devices } from './common';
import Accordion from '../components/Accordion';
import { ServicePage, BgColor } from '../@types/types';
import { RichTextContent } from './StyledComponents';

type Props = {
  header: string;
  instructions: { Content: string; Header: string }[];
  bgColor: BgColor;
};

const PriceSection = ({ header, instructions, bgColor }: Props) => {
  return (
    <Background bgColor={bgColor}>
      <ContentContainer>
        <Stack axis="y" spacing="medium" align="left">
          <Header variant="title-2">{header}</Header>
        </Stack>
      </ContentContainer>
      <ContentContainer>
        {instructions.map((item, index) => (
          <Accordion key={index} header={item.Header} text={item.Content} />
        ))}
      </ContentContainer>
      <Spacing axis="y" amount="large" />
    </Background>
  );
};

export default PriceSection;

const Background = styled.div<{ bgColor: string }>`
  background-color: ${(props: any) => props.theme.colors[props.bgColor]};
  padding: 24px 0px;
`;

const ContentContainer = styled.div`
  padding: ${(props) => props.theme.spacing.medium} 0px;
  width: 950px;
  margin-left: auto;
  margin-right: auto;

  @media ${devices.laptop} {
    padding: ${(props) => props.theme.spacing.medium} 0px;
    width: 650px;
  }

  @media ${devices.mobileXL} {
    width: 90%;
  }
`;

const Header = styled(Text)`
  margin-top: ${(props) => props.theme.spacing.xlarge};

  @media ${devices.mobileXL} {
    text-align: center;
  }
`;
