import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { Text, devices } from '../common';
import { Post } from '../../@types/types';
import { RichTextContent } from '../StyledComponents';

type Props = {
  data: Post;
};

const BlogPostCard = ({ data }: Props) => {
  const { categories, coverImage, title, content, slug } = data;

  const [previewTextS, setPreviewTextS] = useState('');
  const [previewTextM, setPreviewTextM] = useState('');
  const [previewTextL, setPreviewTextL] = useState('');

  const removeMRKDWN = (str: string) => {
    return str.replaceAll('#', '').replaceAll('\n', ' ');
  };

  const ellipsify = (str: string) => {
    if (str.length >= 33) {
      setPreviewTextS(removeMRKDWN(str).substring(0, 33) + '...');
      setPreviewTextM(removeMRKDWN(str).substring(0, 42) + '...');
      setPreviewTextL(removeMRKDWN(str).substring(0, 100) + '...');
    } else {
      setPreviewTextS(removeMRKDWN(str));
      setPreviewTextM(removeMRKDWN(str));
      setPreviewTextL(removeMRKDWN(str));
    }
  };

  useEffect(() => {
    content && ellipsify(content);
  }, [content]);

  return (
    <BlogCardContainer to={`/blogi/${slug}`}>
      <ImageWrapper className="imageWrapper">
        <Image className="image" image={coverImage.publicURL} />
      </ImageWrapper>
      <TagContainer>
        {categories.map(({ name }: any) => (
          <Tag key={name}>#{name}</Tag>
        ))}
      </TagContainer>
      <BlogTextContainer>
        <Title variant="title-3">{title}</Title>
        <BlogTextMobile>
          {content && previewTextS !== '' && (
            <Content blogCard={true}>{previewTextS}</Content>
          )}
        </BlogTextMobile>
        <BlogTextTablet>
          {content && previewTextM !== '' && (
            <Content blogCard={true}>{previewTextM}</Content>
          )}
        </BlogTextTablet>
        <BlogTextDesktop>
          {content && previewTextL !== '' && (
            <Content blogCard={true}>{previewTextL}</Content>
          )}
        </BlogTextDesktop>
      </BlogTextContainer>
    </BlogCardContainer>
  );
};

export default BlogPostCard;

const BlogCardContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  flex: 0 0 47%;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin: ${(props) => props.theme.spacing.small};
  box-shadow: 0px 3px 6px #0000001a;
  overflow: hidden;
  border-radius: 5px;
  text-decoration: none;
  z-index: 2;

  &:hover {
    .image {
      transform: scale(1.1);
      -webkit-transform: scale(1.1, 1.1);
    }
  }
`;

const BlogTextContainer = styled.div`
  padding: ${(props) => props.theme.spacing.medium};
  background-color: ${(props: any) => props.theme.colors.white};
  width: 100%;
  line-height: 20px;
  overflow: hidden;
  white-space: pre-wrap;
  max-height: 120px;
  z-index: 1;

  @media ${devices.mobileXL} {
    max-height: 115px;
    padding: ${(props) => props.theme.spacing.medium}
      ${(props) => props.theme.spacing.default};
  }
`;

const ImageWrapper = styled.div`
  height: 300px;
  width: 100%;
  overflow: hidden;
  position: relative;

  @media ${devices.mobileXL} {
    height: 200px;
  }
`;

const Image = styled.div<{ image: string }>`
  height: 100%;
  width: 100%;
  background-image: url(${(props) => props.image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  ${(props) => props.theme.imageTransition};
`;

const Tag = styled.div`
  border-radius: 24px;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  color: #000;
  width: max-content;
  max-width: 300px;
  margin: ${(props) => props.theme.spacing.small};
  font-size: 18px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  padding: ${(props) => props.theme.spacing.xsmall}
    ${(props) => props.theme.spacing.small};

  @media ${devices.mobileXL} {
    font-size: 16px;
    max-width: 225px;
  }
`;

const Content = styled(RichTextContent)``;

const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: absolute;
  right: 5px;
  top: 5px;
`;

const BlogTextMobile = styled.span`
  display: none;

  @media ${devices.mobileXL} {
    display: block;
  }
`;

const BlogTextTablet = styled.span`
  display: none;

  @media ${devices.tablet} {
    display: block;
  }

  @media ${devices.mobileXL} {
    display: none;
  }
`;

const BlogTextDesktop = styled.span`
  display: block;
  font-size: 18px;

  @media ${devices.tablet} {
    display: none;
  }
`;

const Title = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
