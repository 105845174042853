import React from 'react';
import { graphql } from 'gatsby';
import { SEO } from '../components/common';
import HeroSection from '../components/HeroSection';
import { CompanyPage } from '../@types/types';
import OfficeContact from '../components/ContactInfoSection';
import Article from '../components/Article';

type Props = {
  data: { page: CompanyPage };
  location: Location;
};

const Company = ({ data, location }: Props) => {
  const {
    page: {
      PageTopSection,
      ContentTitle,
      Content,
      Contact,
      ContactTitle,
      ContactText,
      MetaData,
    },
  } = data;

  return (
    <>
      <SEO
        pathname={location.pathname}
        title={MetaData.MetaTitle}
        description={MetaData.MetaDescription}
        keywords={MetaData.metaKeywords}
      />

      <HeroSection topSection={PageTopSection} />
      <Article title={ContentTitle} content={Content} />
      <OfficeContact
        contactTitle={ContactTitle}
        contactContent={ContactText}
        contactEmployees={Contact}
        color="blue"
      />
    </>
  );
};

export default Company;

export const companyQuery = graphql`
  query CompanyPageQuery {
    page: strapiYritys {
      ...strapiYritysFragment
    }
  }
`;
