import React from 'react';
import styled from '@emotion/styled';

import { Content, Text, Spacing, devices } from './common';
import PersonCard from './PersonCard';
import { Color } from '../theme';
import { ContactPerson } from '../@types/types';

type Props = {
  contactTitle: string;
  contactContent: string;
  contactEmployees: ContactPerson[];
  color?: Color;
};

const ContactInfoSection = ({
  contactTitle,
  contactContent,
  contactEmployees,
  color,
}: Props) => {
  return (
    <Container color={color}>
      <Content>
        <Text variant="title-2">{contactTitle}</Text>
        <Spacing axis="y" amount="medium" />
        {contactContent && <Text variant="body">{contactContent}</Text>}

        {contactEmployees && (
          <Employees>
            {contactEmployees.map((employee, index) => {
              return <CustomPersonCard key={index} contact={employee} />;
            })}
          </Employees>
        )}
      </Content>
    </Container>
  );
};

export default ContactInfoSection;

const Container = styled.div<{ color?: Color }>`
  background-color: ${(props) =>
    props.color
      ? props.theme.colors[props.color]
      : props.theme.colors.lightBlue};
  padding: ${(props) => props.theme.spacing.xxxlarge} 0px;
`;

const Employees = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: ${(props) => props.theme.spacing.large};

  @media ${devices.mobileXL} {
    flex-direction: column;
  }
`;

const CustomPersonCard = styled(PersonCard)`
  @media ${devices.mobileXL} {
    max-width: 100%;
    width: 100%;
  }
`;
