import React from 'react';
import styled from '@emotion/styled';
import { Employee } from '../@types/types';
import { Content, Stack, devices, Text } from './common';
import { formatDate } from '../utils/common';
import { RichTextContent } from './StyledComponents';
import { Color } from '../theme';

type Props = {
  content: any;
  width: string | '100%';
  bgColor: Color;
  height?: string;
  date?: string;
  largeText?: boolean;
  padding?: boolean;
  blog?: boolean;
  employeeData?: Employee;
};

const RichTextSection = ({
  content,
  width,
  bgColor,
  height,
  date,
  largeText,
  padding,
  blog,
  employeeData,
}: Props) => {
  return (
    <Container height={height}>
      <Background bgColor={bgColor} hasPadding={padding}>
        <Content>
          <Stack axis={'y'} spacing={'medium'} align={'left'}>
            <DateAuthorContainer>
              {date && (
                <Date variant="body-s-semi-bold">{formatDate(date)}</Date>
              )}
              {blog && employeeData && (
                <AuthorCard>
                  <AuthorImageContainer>
                    <AuthorImage image={employeeData.Picture?.publicURL} />
                  </AuthorImageContainer>
                  <AuthorName variant={'body'}>{employeeData.Name}</AuthorName>
                </AuthorCard>
              )}
            </DateAuthorContainer>

            {content && (
              <RichTextContent width={width} largeText={largeText} blog={blog}>
                {content}
              </RichTextContent>
            )}
          </Stack>
        </Content>
      </Background>
    </Container>
  );
};

export default RichTextSection;

const Container = styled.div<{ height: string | undefined }>`
  height: ${(props) => props.height || '800px'};
`;

const Background = styled.div<{ bgColor: string; hasPadding?: boolean }>`
  background-color: ${(props: any) => props.theme.colors[props.bgColor]};
  height: 100%;

  @media ${devices.tablet} {
    padding: ${(props) =>
      props.hasPadding &&
      'calc(0.15 * 100vw - calc(0.0867 * 100vw)) 0px calc(0.2 * 100vw) 0px'};
  }

  @media ${devices.mobileXL} {
    padding: ${(props) =>
      props.hasPadding &&
      'calc(0.15 * 100vw - calc(0.0867 * 100vw)) 0px calc(0.2 * 100vw) 0px'};
  }
`;

const Date = styled(Text)`
  max-width: 70ch;
  margin: 0px 30px 0px 0px;
  padding: 0px;
  color: inherit;
  text-align: inherit;
  font-family: attribute-mono, sans-serif;
  font-size: 16px;
  line-height: 1.4;
  text-transform: uppercase;

  @media ${devices.mobileXL} {
    margin-bottom: 20px;
  }
`;

const AuthorCard = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  border-left: 2px solid black;
  padding-left: ${(props) => props.theme.spacing.medium};

  @media ${devices.mobileXL} {
    width: max-content;
    max-width: 100%;
    flex-direction: column;
    border: none;
    padding-left: 0px;
  }
`;

const AuthorImageContainer = styled.div`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  min-height: 40px;
  min-width: 40px;
  margin-right: ${(props) => props.theme.spacing.default};

  @media ${devices.mobileXL} {
    margin-bottom: 10px;
  }
`;

const AuthorImage = styled.div<{ image: string }>`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;

const AuthorName = styled(Text)`
  max-width: 70ch;
  margin: 0px;
  padding: 0px;
  color: inherit;
  text-align: inherit;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.3;
`;

const DateAuthorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto;

  @media ${devices.mobileXL} {
    flex-direction: column;
    text-align: center;
  }
`;
