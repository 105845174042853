import React from 'react';
import styled from '@emotion/styled';
import { graphql, Link } from 'gatsby';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import RichTextSection from '../components/RichTextSection';
import BlogHeroSection from '../components/Blog/BlogHeroSection';
import { Post, Site } from '../@types/types';
import { SEO, devices, Text, Stack, Icon, Spacing } from '../components/common';

type Props = {
  data: { site: Site; blogPost: Post; next: Post; previous: Post };
  location: Location;
};

const BlogTemplate = ({ data, location }: Props) => {
  const {
    site: {
      siteMetadata: { siteUrl },
    },
    blogPost,
    next,
    previous,
  } = data;

  const seoDescription = blogPost.content?.substring(0, 270);

  const topSectionData = {
    PageTitle: blogPost.title,
  };

  return (
    <Container>
      <SEO
        pathname={location.pathname}
        title={blogPost.title}
        description={seoDescription}
        socialImage={`${siteUrl}${blogPost.coverImage?.publicURL}`}
        publishedAt={blogPost.publishedAt}
        isArticle
      />
      <BlogHeroSection
        topSection={topSectionData}
        image={blogPost.coverImage?.publicURL}
      />
      <Spacing axis="y" amount="large" />
      <RichTextSection
        content={blogPost.content}
        width="100%"
        bgColor="white"
        height="auto"
        date={blogPost.publishedAt}
        padding={false}
        blog={true}
        employeeData={blogPost.employee}
      />
      <Spacing axis="y" amount="large" />
      <Navigation>
        {previous && (
          <NavLink to={`/blogi/${previous.slug}`}>
            <Stack axis="x" spacing="default" align="center">
              <Icon icon={IoIosArrowBack as any} size={24} color="black" />
              <BlogLable variant="body-s-semi-bold" color="black">
                SEURAAVA
              </BlogLable>
            </Stack>
          </NavLink>
        )}

        {next && (
          <NavLinkNext to={`/blogi/${next.slug}`}>
            <Stack axis="x" spacing="default" align="center">
              <BlogLable variant="body-s-semi-bold" color="black">
                EDELLINEN
              </BlogLable>
              <Icon icon={IoIosArrowForward as any} size={24} color="black" />
            </Stack>
          </NavLinkNext>
        )}
      </Navigation>
    </Container>
  );
};

export default BlogTemplate;

export const query = graphql`
  query BlogTemplateQuery(
    $slug: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      ...siteFragment
    }
    blogPost: strapiBlogPosts(slug: { eq: $slug }) {
      ...strapiBlogPostsFragment
    }
    previous: strapiBlogPosts(id: { eq: $previousPostId }) {
      slug
    }
    next: strapiBlogPosts(id: { eq: $nextPostId }) {
      slug
    }
  }
`;

const Container = styled.div`
  width: 70ch;
  margin-left: auto;
  margin-right: auto;

  @media ${devices.laptopM} {
    width: 100%;
  }
`;

const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 100px;

  @media ${devices.laptopM} {
    width: 90%;
    margin: auto;
  }

  @media ${devices.mobileS} {
    flex-direction: column;
    width: max-content;
    justify-content: space-around;
  }
`;

const BlogLable = styled(Text)`
  letter-spacing: 2px;
`;

const NavLink = styled(Link)`
  width: max-content;
`;

const NavLinkNext = styled(Link)`
  width: max-content;
  position: absolute;
  right: 0px;

  @media ${devices.laptopM} {
    position: relative;
  }
`;
