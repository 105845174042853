import { IS_BROWSER } from '../constants';

export const getUrlParam = (name: string) => {
  if (!IS_BROWSER) {
    return null;
  }

  try {
    const params = new URLSearchParams(location.search);
    return params.get(name);
  } catch (error) {
    return null;
  }
};

export const setUrlParam = (name: string, value: number | string) => {
  if (!IS_BROWSER) {
    return;
  }

  try {
    const params = new URLSearchParams(location.search);

    if (value) {
      params.set(name, `${value}`);
    } else {
      params.delete(name);
    }

    history.replaceState({}, '', `${location.pathname}?${params}`);
  } catch (error) {}
};
