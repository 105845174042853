import React from 'react';
import styled from '@emotion/styled';
import shuffle from 'lodash/shuffle';
import { Content, Spacing, Text, devices } from './common';
import ProfileCard from './ProfileCard';
import { Employee } from '../@types/types';

interface Props {
  employees?: Employee[];
  shuffled?: boolean;
}

export default function Employees({ employees, shuffled = false }: Props) {
  return employees?.length ? (
    <Container bgColor={'white' || 'lightBlue'}>
      <>
        <Content>
          <EmployeeTitle variant="title-2">Asiantuntijamme</EmployeeTitle>
          <Spacing axis="y" amount="xxlarge" />
          <EmployeeContainer>
            {(shuffled ? shuffle(employees) : employees).map(
              (employee: Employee, index: number) => (
                <ProfileCard key={index} employeeData={employee} />
              )
            )}
          </EmployeeContainer>
        </Content>
      </>
    </Container>
  ) : null;
}

const EmployeeContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;

  @media ${devices.laptopXS} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${devices.mobileXL} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const EmployeeTitle = styled(Text)`
  @media ${devices.mobileXL} {
    text-align: center;
  }
`;

const Container = styled.div<{ bgColor: string }>`
  background-color: ${(props: any) => props.theme.colors[props.bgColor]};
  padding: 100px 0px;
`;
