import React from 'react';
import styled from '@emotion/styled';
import { Text, Spacing, devices } from '../common';
import { HomePage } from '../../@types/types';
import HelpGuide from '../HelpGuide';

type Props = {
  data: {
    page: HomePage;
  };
};

const HomeHero = ({ data }: Props) => {
  const {
    page: {
      BannerPicture,
      PageTopSection: { PageTitle, TopSectionText },
    },
  } = data;

  return (
    <Container>
      <TextSection>
        <Title variant="title-1" color="black">
          {PageTitle}
        </Title>
        <Spacing amount={'medium'} axis="y" />
        <HeroText variant="subtitle" color="black">
          {TopSectionText}
        </HeroText>
        <HelpGuide />
      </TextSection>
      <ImageContainer src={BannerPicture.publicURL} />
    </Container>
  );
};

export default HomeHero;

const Container = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  background-color: ${(props) => props.theme.colors.lightBlue};
  min-height: 90vh;
  height: max-content;

  @media ${devices.laptop} {
    grid-template-columns: 1fr;
    min-height: 65vh;
  }

  @media ${devices.mobileXL} {
    height: auto;
    min-height: 70vh;
    display: block;
  }
`;

const TextSection = styled.div`
  padding: 80px;
  height: auto;
  margin: auto;
  display: block;

  @media ${devices.tablet} {
    padding: ${(props) => props.theme.spacing.default};
  }

  @media ${devices.mobileXL} {
    width: 95%;
  }
`;

const ImageContainer = styled.div<{ src: string }>`
  height: 100%;
  width: 100%;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  ${(props) => props.theme.imageTransition};

  @media ${devices.laptop} {
    display: none;
  }
`;

const Title = styled(Text)`
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;

  @media ${devices.mobileXL} {
    text-align: center;
  }
`;

const HeroText = styled(Text)`
  @media ${devices.mobileXL} {
    text-align: center;
  }
`;
