import React from 'react';
import styled from '@emotion/styled';
import { Stack, Text, Spacing, devices } from './common';
import TableAccordion from '../components/Accordion/TableAccordion';
import { ServicePage, BgColor } from '../@types/types';
import { RichTextContent } from './StyledComponents';

type Props = {
  header: string;
  text?: string;
  priceData: ServicePage['PriceTable'];
  width: string;
  bgColor: BgColor;
  image: string;
};

const PriceSection = ({
  header,
  text,
  priceData,
  width,
  bgColor,
  image,
}: Props) => {
  return (
    <Background bgColor={bgColor} id="prices">
      <ArrowContainer>
        <Image src={image} alt="picture of an arrow" />
        <ContentContainer>
          <Stack axis="y" spacing="medium" align="left">
            <Header variant="title-2">{header}</Header>
            {text && <RichTextContent width={width}>{text}</RichTextContent>}
          </Stack>
        </ContentContainer>
        <ContentContainer>
          {priceData.map((item, index) => (
            <TableAccordion
              key={index}
              header={item.TableName}
              additionalInfo={item.AdditionalInfo}
              data={item.PriceTableRow}
            />
          ))}
        </ContentContainer>
      </ArrowContainer>
      <Spacing axis="y" amount="xxxlarge" />
    </Background>
  );
};

export default PriceSection;

const Background = styled.div<{ bgColor: string }>`
  background-color: ${(props: any) => props.theme.colors[props.bgColor]};
  padding: 24px 0px;
  position: relative;
`;

const ContentContainer = styled.div`
  padding: ${(props) => props.theme.spacing.medium} 0px;
  width: 950px;
  margin-left: auto;
  margin-right: auto;

  @media ${devices.laptop} {
    padding: ${(props) => props.theme.spacing.medium} 0px;
    width: 650px;
  }

  @media ${devices.mobileXL} {
    width: 90%;
  }
`;

const Header = styled(Text)`
  margin-top: ${(props) => props.theme.spacing.xlarge};
`;

const Image = styled.img`
  width: 120px;
  height: 160px;
  position: absolute;
  left: 0px;
  top: 115px;

  @media ${devices.laptopL} {
    display: none;
  }
`;

const ArrowContainer = styled.div`
  width: 85%;
  max-width: 1300px;
  margin: auto;
  position: relative;

  @media ${devices.laptopL} {
    width: 100%;
  }
`;
