import React from 'react';
import styled from '@emotion/styled';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { DownArrow } from '../SVG';
import { devices, Text } from '../common';
import { ServicePage } from '../../@types/types';

type Props = {
  header: string;
  additionalInfo?: string;
  data: ServicePage['PriceTable'][0]['PriceTableRow'];
};

export default function CustomAccordion({
  header,
  additionalInfo,
  data,
}: Props) {
  return (
    <RootContainer>
      <Container>
        <AccordionSummary
          expandIcon={<DownArrow />}
          aria-controls={'panel1a-content'}
          id={'panel1a-header'}
        >
          <Text variant={'title-4'} align="left">
            {header}
          </Text>
        </AccordionSummary>
        {additionalInfo && (
          <InfoSection>
            <Text variant="body-small">{additionalInfo}</Text>
          </InfoSection>
        )}
        <AccordionContainer>
          <AccordionDetails>
            <Table>
              <colgroup>
                <col
                  style={{
                    width: `${
                      data.some((item) => item.SvHinta !== null) ? '68' : '75'
                    }%`,
                  }}
                />
              </colgroup>
              <thead>
                <TRParent>
                  <TH>Palvelu</TH>
                  <TH>Hinta</TH>
                  {data.some((item) => item.SvHinta !== null) && (
                    <TH>Sv-korvauksen jälkeen</TH>
                  )}
                </TRParent>
              </thead>
              <tbody>
                {data.map(
                  (price: ServicePage['PriceTable'][0]['PriceTableRow'][0]) => (
                    <TR key={price.Hinta}>
                      <TD className={'service'}>{price.Palvelut}</TD>
                      <TD>{price.Hinta}</TD>
                      {price.SvHinta ? <TD>{price.SvHinta}</TD> : <TD></TD>}
                    </TR>
                  )
                )}
              </tbody>
            </Table>
          </AccordionDetails>
        </AccordionContainer>
      </Container>
    </RootContainer>
  );
}

const RootContainer = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;

const AccordionContainer = styled.div`
  overflow-x: auto;
  @media ${devices.mobileL} {
    .MuiAccordionDetails-root {
      padding: ${(props) => props.theme.spacing.default} 0px;
    }
  }
`;

const Container = styled(Accordion)`
  padding: 20px;
`;

const InfoSection = styled.div`
  padding: 0px 24px;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;

  @media ${devices.mobileXL} {
    table-layout: auto;
  }
`;

const TH = styled.th`
  text-align: left;
  padding: 8px;
  font-size: 16px;
`;

const TD = styled.td`
  text-align: left;
  padding: 16px 8px;
  font-size: 16px;
  white-space: normal;
`;

const TRParent = styled.tr`
  border-bottom: 2px solid #cbcbcb;
`;

const TR = styled.tr`
  &:nth-of-type(even) {
    background-color: #f7f7f7;
  }
`;
