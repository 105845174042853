import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import Select from 'react-select';
import type { StylesConfig } from 'react-select';
import ProfileCard from '../components/ProfileCard';
import { SEO, Content, devices, Text } from '../components/common';
import {
  EmployeePage,
  Employee,
  Occupation,
  ServiceType,
  Psychotherapy,
} from '../@types/types';
import HeroSection from '../components/HeroSection';
import theme from '../theme';

type Props = {
  data: {
    page: EmployeePage;
    employees: { nodes: Employee[] };
    priorityEmployees: { nodes: Employee[] };
    occupations: { nodes: Occupation[] };
    services: { nodes: ServiceType[] };
    psychotherapies: { nodes: Psychotherapy[] };
  };
  location: Location;
};

const Employees = ({ data, location }: Props) => {
  const {
    page: { MetaData, PageTopSection },
    employees,
    priorityEmployees,
    occupations,
    services,
    psychotherapies,
  } = data;

  const [occupationFilter, setOccupationFilter] = useState(0);
  const [serviceFilter, setServiceFilter] = useState(0);
  const [psychoFilter, setPsychoFilter] = useState(0);
  const [urlFSet, setUrlFSet] = useState(false);
  const [employeeSearchValue, setEmployeeSearchValue] = useState('');
  const [randomPriorityData, setRandomPriorityData] = useState<any[]>([]);
  const [randomNonPriorityData, setRandomNonPriorityData] = useState<any[]>([]);

  // init filters, if url parameters
  if (location.search !== '' && !urlFSet) {
    const params = location.search.replace('?', '').split('&');
    params.map((param) => {
      const split = param.split('=');
      switch (split[0]) {
        case 'o':
          setOccupationFilter(parseInt(split[1]));
          setUrlFSet(true);
          break;
        case 'p':
          setPsychoFilter(parseInt(split[1]));
          setUrlFSet(true);
          break;
        default:
          setUrlFSet(true);
      }
    });
  }

  const shuffleEmployee = (array: any[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const employeeData = employees.nodes;
  const priorityEmployeeData = priorityEmployees.nodes;
  const employeeSearchData = [...priorityEmployeeData, ...employeeData];

  const filterEmployees = (employees: Employee[]) => {
    if (occupationFilter === 0) {
      // When no filters are applied
      return employees;
    } else if (occupationFilter === 3) {
      if (psychoFilter === 0) {
        // Only occupation filter is applied
        return employees.filter((employee) =>
          employee.occupations.some((i) => i.id === occupationFilter)
        );
      } else {
        // Occupation and psycho filter applied
        return employees
          .filter((employee) =>
            employee.occupations.some((i) => i.id === occupationFilter)
          )
          .filter((employee) =>
            employee.psycotherapies.some((i) => i.id === psychoFilter)
          );
      }
    } else if (serviceFilter === 0) {
      // Only occupation filter is applied
      return employees.filter((employee) =>
        employee.occupations.some((i) => i.id === occupationFilter)
      );
    } else {
      // If both occupation and service filters are applied
      const occupationFiltered = employees.filter((employee) =>
        employee.occupations.some((i) => i.id === occupationFilter)
      );
      return employees
        .filter((employee) =>
          employee.service_types.some((i) => i.id === serviceFilter)
        )
        .filter((employee) => occupationFiltered.includes(employee));
    }
  };

  useEffect(() => {
    setRandomPriorityData(shuffleEmployee(priorityEmployees.nodes));
    setRandomNonPriorityData(shuffleEmployee(employees.nodes));
  }, []);

  const searchEmployees = (employee: string) => {
    if (employee === '') {
      return employeeData;
    } else {
      return employeeSearchData.filter((e) =>
        e.Name.toLowerCase().includes(employee.toLowerCase())
      );
    }
  };

  useEffect(() => {
    searchEmployees(employeeSearchValue);
  }, [employeeSearchValue]);

  type Options = {
    value: string;
    label: string;
  };

  const occupationFilters: Options[] = [];
  const psychotherapyFilters: Options[] = [];

  const customStyles: StylesConfig = {
    singleValue: (provided, state: { isDisabled: boolean }) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      const backgroundColor = `${theme.colors.darkBlue}`;
      const color = `${theme.colors.white}`;
      const padding = `${theme.spacing.xxsmall} ${theme.spacing.default}`;
      const borderRadius = '24px';
      const width = 'max-content';

      return {
        ...provided,
        opacity,
        transition,
        backgroundColor,
        color,
        padding,
        borderRadius,
        width,
      };
    },
  };

  occupations.nodes.forEach((occupation) =>
    occupationFilters.push({
      value: occupation.strapiId.toString(),
      label: occupation.Occupation,
    })
  );

  psychotherapies.nodes.forEach((psycho) =>
    psychotherapyFilters.push({
      value: psycho.strapiId?.toString() || '',
      label: psycho.Title,
    })
  );

  const placeholder = 'Valitse';

  return (
    <>
      <SEO
        pathname={location.pathname}
        title={MetaData.MetaTitle}
        description={MetaData.MetaDescription}
        keywords={MetaData.metaKeywords}
      />

      <HeroSection topSection={PageTopSection} />
      <DropdownSection>
        <DropdownContainer>
          <Text variant="body-s-semi-bold">Hae</Text>
          <CustomInput
            className={'react-select-container'}
            placeholder={'Hae nimellä'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setEmployeeSearchValue(e.target.value);
            }}
            tabIndex={0}
          />
        </DropdownContainer>
        <DropdownContainer>
          <Text variant="body-s-semi-bold">Ammattiryhmä</Text>
          <CustomSelect
            options={occupationFilters}
            defaultValue={placeholder}
            className={'react-select-container'}
            classNamePrefix={'react-select'}
            placeholder={placeholder}
            styles={customStyles}
            onChange={(e: { value: string }) => {
              setServiceFilter(0);
              setPsychoFilter(0);
              setOccupationFilter(parseInt(e.value));
            }}
            tabIndex={0}
            isSearchable={false}
          />
        </DropdownContainer>

        {services.nodes.filter(
          (service) => service.occupation.id === occupationFilter
        ).length !== 0 && (
          <DropdownContainer>
            <Text variant="body-s-semi-bold">Palvelu</Text>
            <CustomSelect
              options={services.nodes
                .filter((service) => service.occupation.id === occupationFilter)
                .map((service) => {
                  return {
                    value: service.strapiId.toString(),
                    label: service.Service,
                  };
                })}
              className={'react-select-container'}
              classNamePrefix={'react-select'}
              placeholder={placeholder}
              styles={customStyles}
              onChange={(e: { value: string }) =>
                setServiceFilter(parseInt(e.value))
              }
              isSearchable={false}
            />
          </DropdownContainer>
        )}

        {occupationFilter === 3 && (
          <DropdownContainer>
            <Text variant="body-s-semi-bold">Psykoterapiasuuntaus</Text>
            <CustomSelect
              options={psychotherapyFilters}
              className={'react-select-container'}
              classNamePrefix={'react-select'}
              placeholder={placeholder}
              styles={customStyles}
              onChange={(e: { value: string }) =>
                setPsychoFilter(parseInt(e.value))
              }
              isSearchable={false}
            />
          </DropdownContainer>
        )}
      </DropdownSection>

      <ProfileSection>
        {employeeSearchValue !== '' &&
          searchEmployees(employeeSearchValue).map(
            (employee: Employee, index: number) => {
              return <ProfileCard key={index} employeeData={employee} />;
            }
          )}

        {employeeSearchValue === '' &&
          filterEmployees([
            ...randomPriorityData,
            ...randomNonPriorityData,
          ]).map((employee: Employee, index: number) => {
            return <ProfileCard key={index} employeeData={employee} />;
          })}
      </ProfileSection>
    </>
  );
};

export default Employees;

export const expertQuery = graphql`
  query ExpertPageQuery {
    page: strapiAsiantuntijat {
      MetaData {
        MetaDescription
        MetaTitle
        metaKeywords
      }
      PageTopSection {
        PageTitle
        TopSectionText
      }
    }
    employees: allStrapiEmployees(
      filter: { priorityEmployee: { in: [null, false] } }
    ) {
      nodes {
        ...strapiEmployeesFragment
      }
    }
    priorityEmployees: allStrapiEmployees(
      filter: { priorityEmployee: { eq: true } }
    ) {
      nodes {
        ...strapiEmployeesFragment
      }
    }
    occupations: allStrapiOccupation {
      nodes {
        Occupation
        strapiId
      }
    }
    services: allStrapiServiceType {
      nodes {
        Service
        strapiId
        occupation {
          id
        }
      }
    }
    psychotherapies: allStrapiPsycotherapy {
      nodes {
        Title
        strapiId
      }
    }
  }
`;

const ProfileSection = styled(Content)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-bottom: calc(0.12 * 100vw);

  @media ${devices.tablet} {
    padding-bottom: 200px;
  }

  @media ${devices.mobileXL} {
    flex-direction: column;
  }
`;

const DropdownSection = styled(Content)`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex-wrap: wrap;

  @media ${devices.tablet} {
    padding: 0px ${(props) => props.theme.spacing.medium}
      ${(props) => props.theme.spacing.xlarge};
  }

  @media ${devices.mobileXL} {
    flex-direction: column;
    padding: 0px ${(props) => props.theme.spacing.medium}
      ${(props) => props.theme.spacing.large};
  }
`;

const DropdownContainer = styled.div`
  padding-right: 15px;
  padding-bottom: 80px;

  @media ${devices.laptop} {
    padding: ${(props) => props.theme.spacing.small} 0px;
    margin-right: 15px;
  }
`;

const CustomSelect = styled(Select)`
  margin-top: ${(props) => props.theme.spacing.small};

  &.react-select-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    padding: ${(props) => props.theme.spacing.xxsmall};
    border: 1px solid ${(props) => props.theme.colors.darkBlue};
    border-radius: ${(props) => props.theme.spacing.xsmall};
    min-width: 300px;
  }

  .react-select__control {
    border: none;
    width: 100%;
    cursor: pointer;
    box-shadow: none;

    .label {
      display: none;
    }

    .result {
      display: block;
    }
  }

  .react-select__control--is-focused {
    ${(p) => p.theme.focus}
  }

  .react-select__placeholder {
    color: ${(props) => props.theme.colors.black};
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__indicator {
    color: ${(props) => props.theme.colors.black};
  }

  .react-select__menu {
    .label {
      display: block;
    }

    .result {
      display: none;
    }
  }

  @media ${devices.mobileS} {
    &.react-select-container {
      width: 100%;
      min-width: 210px;
    }
  }
`;

const CustomInput = styled.input`
  margin-top: ${(props) => props.theme.spacing.small};
  padding: 9px;
  border: 1px solid ${(props) => props.theme.colors.darkBlue};
  border-radius: ${(props) => props.theme.spacing.xsmall};
  min-width: 300px;
  width: 100%;
  ${(props) => props.theme.typography.body}
`;
