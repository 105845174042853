import React, { useRef } from 'react';
import styled from '@emotion/styled';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Content, Text, Spacing, WhiteButtonLink, devices } from './common';
import { CurrentShowcase } from '../@types/types';
import { BigNextPage, BigPreviousPage } from './SVG';
import { RichTextContent } from './StyledComponents';

type Props = {
  current: CurrentShowcase[];
};

const CurrentNews = ({ current }: Props) => {
  const settings = {
    dots: true,
    slidesToShow: 1,
    arrows: false,
  };
  const slider = useRef(null);
  const next = () => slider.current.slickNext();
  const prev = () => slider.current.slickPrev();

  return (
    <Container>
      <TitleSection>
        <Text variant="title-4">Ajankohtaista</Text>
      </TitleSection>
      <CarouselWrapper>
        <PrevButton onClick={prev}>
          <BigPreviousPage />
        </PrevButton>
        <CarouselSection>
          <Slider ref={slider} {...settings}>
            {current.slice(0, 5).map((news: CurrentShowcase) => {
              return (
                <Wrapper key={news.published_at}>
                  <CarouselItem>
                    <Text variant="title-2">{news.Title}</Text>
                    <Spacing axis="y" amount="medium" />
                    <RichTextContent variant="body">
                      {news.Preview}
                    </RichTextContent>
                    <Spacing axis="y" amount="xlarge" />
                    <WhiteButtonLink href={`${news.slug}`}>
                      Lue lisää
                    </WhiteButtonLink>
                    <Spacing axis="y" amount="xlarge" />
                  </CarouselItem>
                </Wrapper>
              );
            })}
          </Slider>
        </CarouselSection>
        <NextButton onClick={next}>
          <BigNextPage />
        </NextButton>
      </CarouselWrapper>
    </Container>
  );
};

const Container = styled.div`
  background-color: #ebf5ff;
  padding: 100px 0px 0px;
`;

const TitleSection = styled(Content)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const CarouselWrapper = styled.div`
  position: relative;
`;

const CarouselSection = styled.div`
  padding: 40px;
`;

const CarouselItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 600px;
  word-break: normal;
  overflow-wrap: anywhere;

  @media ${devices.mobileXL} {
    width: 100%;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }

  @media ${devices.mobileS} {
    word-break: break-all;
  }
`;

const Wrapper = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding-left: 7%;
  padding-right: 7%;
`;

const NextButton = styled.button`
  display: block;
  position: absolute;
  right: 10px;
  top: 40%;
  transform: translateY(-50%);
  z-index: 1;

  svg {
    width: 80px;
    height: 80px;

    @media ${devices.mobileXL} {
      width: 50px;
      height: 50px;
    }
  }

  @media ${devices.mobileXL} {
    right: 0px;
  }
`;

const PrevButton = styled.button`
  display: block;
  position: absolute;
  left: 10px;
  top: 40%;
  transform: translateY(-50%);
  z-index: 1;

  svg {
    width: 80px;
    height: 80px;

    @media ${devices.mobileXL} {
      width: 50px;
      height: 50px;
    }
  }

  @media ${devices.mobileXL} {
    left: 0px;
  }
`;

export default CurrentNews;
