import React from 'react';
import { ThemeProvider } from '@emotion/react';

import theme from '../theme';
import { AriaLiveContextProvider } from './common';

const Providers: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <AriaLiveContextProvider>{children}</AriaLiveContextProvider>
    </ThemeProvider>
  );
};

export default Providers;
