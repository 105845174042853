import React from 'react';
import styled from '@emotion/styled';
import { Text, Spacing, devices, Content } from './common';
import { PageTopSection } from '../@types/types';

type Props = {
  topSection: PageTopSection;
  image?: string;
};

const HeroSection = ({
  topSection: { PageTitle, TopSectionText },
  image,
}: Props) => {
  return (
    <Container>
      {image ? (
        <ArrowContainer>
          <ArrowImage src={image} alt="picture of an arrow" />
          <Title variant="title-1" color="black">
            {PageTitle}
          </Title>
        </ArrowContainer>
      ) : (
        <Title variant="title-1" color="black">
          {PageTitle}
        </Title>
      )}

      {TopSectionText && (
        <>
          <Spacing amount={'medium'} axis="y" />
          <TopSectionTextContent variant="subtitle" color="black">
            {TopSectionText}
          </TopSectionTextContent>
        </>
      )}
    </Container>
  );
};

export default HeroSection;

const TopSectionTextContent = styled(Text)`
  word-break: normal;
  overflow-wrap: anywhere;
`;

const Container = styled(Content)`
  padding: 80px 0px;

  @media ${devices.mobileXL} {
    padding: ${(props) => props.theme.spacing.xlarge}
      ${(props) => props.theme.spacing.medium};
  }
`;

const Title = styled(Text)`
  word-break: break-word;
`;

const ArrowImage = styled.img`
  width: 100px;
  height: 140px;
  position: absolute;
  right: 0px;
  top: 0px;
  transform: scaleX(-1) rotate(90deg);

  @media ${devices.laptopM} {
    display: none;
  }
`;

const ArrowContainer = styled.div`
  width: 85%;
  max-width: 900px;
  position: relative;

  @media ${devices.laptopM} {
    width: 100%;
  }
`;
