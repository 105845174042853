import React from 'react';
import styled from '@emotion/styled';
import {
  FaCalendarDay as Calendar,
  FaMicrophone as Microphone,
} from 'react-icons/fa';

import { Spacing, Text, BlueButtonBasic, devices } from './common';
import { NextPage } from './SVG';

type Props = {
  title: string;
  date: string;
  trainer: string;
  preview: string;
  slug: string;
};

const TrainingCard = ({ title, date, trainer, preview, slug }: Props) => {
  return (
    <Card>
      <Container>
        <Title variant="title-3">{title}</Title>
        <SmallInfo>
          <InfoItem>
            <Calendar />
            <Spacing axis="x" amount="small" />
            <Text variant="body-small">{date}</Text>
          </InfoItem>

          <Spacing axis="x" amount="medium" />
          <Divider />
          <Spacing axis="x" amount="medium" />

          <InfoItem>
            <Microphone />
            <Spacing axis="x" amount="small" />
            <Text variant="body-small">{trainer}</Text>
          </InfoItem>
        </SmallInfo>
        <Text variant="body-small">{preview}</Text>
      </Container>
      <Button href={`/koulutukset/${slug}/`}>
        Lue lisää
        <NextPage fill="#ffffff" />
      </Button>
    </Card>
  );
};

export default TrainingCard;

const Card = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(p) => p.theme.colors.white};
  padding: ${(p) => p.theme.spacing.large};
  margin: ${(p) => p.theme.spacing.small} 0px;
  justify-content: space-between;

  @media ${devices.laptopM} {
    padding: ${(props) => props.theme.spacing.default};
    flex-direction: column;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
`;

const SmallInfo = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${(p) => p.theme.spacing.small} 0px;

  @media ${devices.mobileXL} {
    flex-direction: column;
  }
`;

const Divider = styled.div`
  border: 1px solid ${(props) => props.theme.colors.grey};

  @media ${devices.mobileXL} {
    display: none;
  }
`;

const Button = styled(BlueButtonBasic)`
  @media ${devices.laptopM} {
    margin-top: 24px;
  }
`;

const Title = styled(Text)`
  word-break: break-word;
`;

const InfoItem = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${(p) => p.theme.spacing.small} 0px;
`;
