import styled from '@emotion/styled';

import { MAX_CONTENT_WIDTH } from '../../constants';
import Stack from './Stack';
import { devices } from './MediaQueries';

const Content = styled(Stack)`
  width: 100%;
  max-width: ${MAX_CONTENT_WIDTH};
  margin-left: auto;
  margin-right: auto;

  @media ${devices.laptopL} {
    width: 90% !important;
  }
`;

export default Content;
