import React, { useRef } from 'react';
import styled from '@emotion/styled';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Text, Spacing, devices } from './common';
import { BigNextPage, BigPreviousPage } from './SVG';
import { JobsPage } from '../@types/types';
import { RichTextContent } from './StyledComponents';

type Props = {
  title: string;
  content: string;
  pictures: JobsPage['OfficeShowcase'][];
  image: string;
};

const OfficeInfo = ({ title, content, pictures, image }: Props) => {
  const settings = {
    dots: false,
    slidesToShow: pictures.length >= 3 ? 3 : 2,
    arrows: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  const slider = useRef(null);
  const next = () => slider.current.slickNext();
  const prev = () => slider.current.slickPrev();

  return (
    <Container>
      <ArrowContainer>
        <ArrowImage src={image} alt="picture of an arrow" />
        <Text variant="title-2" color="black">
          {title}
        </Text>
        <Spacing axis="y" amount="large" />
        <RichTextContent variant="body">{content}</RichTextContent>
      </ArrowContainer>
      <Spacing axis="y" amount="xxlarge" />

      <CarouselWrapper>
        {pictures.length > 3 && (
          <PrevButton onClick={prev}>
            <BigPreviousPage />
          </PrevButton>
        )}
        <Slider ref={slider} {...settings}>
          {pictures.map((item, index) => {
            return (
              <ImageContainer key={index}>
                <Image key={index} src={item.Picture.publicURL} />
              </ImageContainer>
            );
          })}
        </Slider>
        {pictures.length > 3 && (
          <NextButton onClick={next}>
            <BigNextPage />
          </NextButton>
        )}
      </CarouselWrapper>
      <Spacing axis="y" amount="xlarge" />
    </Container>
  );
};

export default OfficeInfo;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: top;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: ${(p) => p.theme.colors.white};
  padding: 80px;

  @media ${devices.mobileXL} {
    padding: ${(props) => props.theme.spacing.xxlarge}
      ${(props) => props.theme.spacing.medium};
  }
`;

const ImageContainer = styled.div`
  width: 100%;
`;

const Image = styled.img`
  width: 95%;
  ${(props) => props.theme.imageTransition};

  @media ${devices.tablet} {
    width: 100%;
  }
`;

const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const NextButton = styled.button`
  display: block;
  position: absolute;
  right: 60px;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  z-index: 2;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 50%;
`;

const PrevButton = styled.button`
  display: block;
  position: absolute;
  left: 60px;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  z-index: 2;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 50%;
`;

const ArrowImage = styled.img`
  width: 100px;
  height: 140px;
  position: absolute;
  right: 0px;
  top: 100px;
  transform: scaleX(-1) rotate(90deg);

  @media ${devices.laptopM} {
    display: none;
  }
`;

const ArrowContainer = styled.div`
  width: 85%;
  max-width: 1000px;
  position: relative;

  @media ${devices.laptopM} {
    width: 100%;
  }
`;
