import React from 'react';
import { graphql } from 'gatsby';
import { SEO } from '../components/common';
import { JobsPage } from '../@types/types';
import HeroSection from '../components/HeroSection';
import Article from '../components/Article';
import OfficeInfo from '../components/OfficeInfo';
import EmployeeQuotes from '../components/EmployeeQuotes';

type Props = {
  data: { page: JobsPage; arrow: { publicURL: string } };
  location: Location;
};

const Jobs = ({ data, location }: Props) => {
  const {
    page: {
      MetaData,
      PageTopSection,
      ContentTitle,
      Content,
      OfficeTitle,
      OfficeContent,
      OfficeShowcase,
      EmployeeComments,
      ContactCard,
    },
    arrow,
  } = data;

  return (
    <>
      <SEO
        pathname={location.pathname}
        title={MetaData.MetaTitle}
        description={MetaData.MetaDescription}
        keywords={MetaData.metaKeywords}
      />
      <HeroSection topSection={PageTopSection} />
      <Article title={ContentTitle} content={Content} employee={ContactCard} />
      <OfficeInfo
        title={OfficeTitle}
        content={OfficeContent}
        pictures={OfficeShowcase}
        image={arrow.publicURL}
      />
      <EmployeeQuotes quotes={EmployeeComments} />
    </>
  );
};

export default Jobs;

export const jobQuery = graphql`
  query AatoslaiseksiQuery {
    page: strapiAatoslaiseksi {
      ...strapiAatoslaiseksiFragment
    }
    arrow: file(name: { eq: "blue-arrow" }) {
      publicURL
    }
  }
`;
