import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { SEO, devices, Text, Stack, Content } from '../components/common';
import HeroSection from '../components/HeroSection';
import { TrainingPage, Training } from '../@types/types';
import TrainingCard from '../components/TrainingCard';
import { RichTextContent } from '../components/StyledComponents';

type Props = {
  data: { page: TrainingPage; trainings: { nodes: Training[] } };
  location: Location;
};

const Trainings = ({ data, location }: Props) => {
  const {
    page: { MetaData, PageTopSection, ContentTitle, Content: TrainingContent },
    trainings: { nodes },
  } = data;

  return (
    <>
      <SEO
        pathname={location.pathname}
        title={MetaData.MetaTitle}
        description={MetaData.MetaDescription}
        keywords={MetaData.metaKeywords}
      />

      <HeroSection topSection={PageTopSection} />

      <Background>
        <Content>
          <Stack axis="y" spacing="medium">
            <Text variant="title-2">{ContentTitle}</Text>
            <RichTextContent variant="body">{TrainingContent}</RichTextContent>
            {nodes.map((training) => (
              <TrainingCard
                key={training.Date}
                title={training.Title}
                preview={training.Preview}
                date={training.Date}
                trainer={training.Trainer}
                slug={training.slug}
              />
            ))}
          </Stack>
        </Content>
      </Background>
    </>
  );
};

export default Trainings;

export const trainingsQuery = graphql`
  query TrainingsPageQuery {
    page: strapiKoulutukset {
      ...strapiKoulutuksetFragment
    }
    trainings: allStrapiTrainings {
      nodes {
        Content
        Date
        Preview
        Title
        Trainer
        slug
      }
    }
  }
`;

const Background = styled.div`
  background-color: ${(p) => p.theme.colors.lightBlue};
  padding: 80px 0px;

  @media ${devices.mobileXL} {
    padding: ${(props) => props.theme.spacing.xxlarge}
      ${(props) => props.theme.spacing.medium};
  }
`;
