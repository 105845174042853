/* eslint-disable camelcase */
import React from 'react';
import { graphql } from 'gatsby';
import { IoIosArrowBack as ArrowBack } from 'react-icons/io';
import styled from '@emotion/styled';

import { SEO, Text, Spacing } from '../components/common';
import Article from '../components/Article';
import { Current as CurrentType } from '../@types/types';

type Props = {
  data: { current: CurrentType };
  location: Location;
};

const Current = ({ data, location }: Props) => {
  const {
    current: { Title, Content, Preview, published_at },
  } = data;

  const seoDescription = Preview.substring(0, 270);
  return (
    <>
      <SEO
        pathname={location.pathname}
        title={Title}
        description={seoDescription}
      />
      <BackContainer>
        <BackButton href={`/`}>
          <ArrowBack />
          <Spacing axis="x" amount="default" />
          <Text variant="title-4">AJANKOHTAISTA</Text>
        </BackButton>
      </BackContainer>
      <Spacing axis="y" amount="default" />
      <Article title={Title} content={Content} date={published_at} />
    </>
  );
};

export default Current;

export const query = graphql`
  query CurrentQuery($slug: String!) {
    current: strapiCurrent(slug: { eq: $slug }) {
      ...strapiCurrentFragment
    }
  }
`;

const BackContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: ${(props) => props.theme.spacing.xxlarge};
`;

const BackButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`;
