import React, { useRef, useState, useMemo, useEffect } from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import VisuallyHidden from '@reach/visually-hidden';
import {
  Content,
  Text,
  SEO,
  useAriaLive,
  Stack,
  TogglableOption,
  devices,
} from '../components/common';
import { setUrlParam, getUrlParam } from '../utils/url';
import HeroSection from '../components/HeroSection';
import BlogPostCard from '../components/Blog/BlogPostCard';
import SearchField from '../components/Blog/SearchField';
import Pagination from '../components/Blog/Pagination';
import { BlogPage, Category, Post } from '../@types/types';

type Props = {
  data: {
    page: BlogPage;
    paginatedBlogs: { nodes: Post[] };
    allBlogs: { nodes: Post[] };
    allCategories: { nodes: Category[] };
  };
  location: Location;
  pageContext: any; // TODO: UPDATE TYPE
};

const Blog = ({ data, location, pageContext }: Props) => {
  const { page, paginatedBlogs, allBlogs, allCategories } = data;

  const metaData = page.MetaData;
  const pageBlogs = paginatedBlogs.nodes;
  const blogs = allBlogs.nodes;
  const categories = allCategories.nodes;

  const isNotFirstPage = location.pathname.split('/')[2];

  const addAriaLiveMessage = useAriaLive();
  const blogRef = useRef<HTMLDivElement>(null);

  const [searchKeyWord, setSearchKeyWord] = useState(
    getUrlParam('search') || ''
  );
  const [filters, setFilters] = useState<string[]>(
    getUrlParam('filters')?.split(',') || []
  );

  const filteredBlogs = useMemo(
    () =>
      blogs.filter(
        (blog: Post) =>
          blog.title.toLowerCase().includes(searchKeyWord.toLowerCase()) &&
          filters.every((filter) =>
            blog.categories
              .map(({ name }: any) => name.toLowerCase())
              .includes(filter)
          )
      ),
    [searchKeyWord, filters, blogs]
  );

  /* Trigger update only if filters change and more than 3 filters are displayed
  otherwise display the last selected filters */
  const displayTrigger = filters.length > 3 ? filters : null;

  const displayedFilters = useMemo(() => {
    const selected = categories.filter(({ name }: any) =>
      filters.includes(name)
    );
    const unselected = categories.filter(
      (name: string) => !filters.includes(name)
    );

    return [...selected, ...unselected].sort();
  }, [displayTrigger]); // eslint-disable-line

  useEffect(() => {
    const timer = setTimeout(() => {
      setUrlParam('search', searchKeyWord);
    });
    return () => clearTimeout(timer);
  }, [searchKeyWord]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setUrlParam('filters', filters.join(','));
    });
    return () => clearTimeout(timer);
  }, [filters]);

  const toggleFilter = (newFilter: string) => {
    const lowercase = newFilter.toLowerCase();
    if (filters.includes(lowercase)) {
      setFilters(filters.filter((filter: string) => filter !== newFilter));
    } else {
      setFilters([...filters, lowercase]);
    }
  };

  /** Updates aria-live notifications accordingly */
  useEffect(() => {
    addAriaLiveMessage(`Julkaisuja: ${filteredBlogs.length}`);
  }, [filteredBlogs.length, addAriaLiveMessage]);

  function onSearchSubmit(e: React.FormEvent) {
    e.preventDefault();
    blogRef.current?.focus();
  }

  const topSectionData = {
    PageTitle: page?.PageTopSection.PageTitle,
    TopSectionText: page?.PageTopSection.TopSectionText,
  };

  return (
    <>
      <SEO
        pathname={location.pathname}
        title={metaData.MetaTitle}
        description={metaData.MetaDescription}
        keywords={metaData.metaKeywords}
      />

      <HeroSection topSection={topSectionData} />

      <Background>
        <BlogContainer>
          <BlogSection>
            <BlogPostGrid
              aria-label={'Blogikirjoitukset'}
              ref={blogRef}
              isNotFirstPage={isNotFirstPage}
            >
              {searchKeyWord !== '' || filters.length !== 0 ? (
                filteredBlogs.length !== 0 ? (
                  filteredBlogs.map((blogPost) => (
                    <BlogPostCard key={blogPost.slug} data={blogPost} />
                  ))
                ) : (
                  <Text variant={'body'}>
                    Ei hakutuloksia hakusanalle {searchKeyWord}{' '}
                    {filters?.map((filter) => `#${filter} `)}
                  </Text>
                )
              ) : (
                pageBlogs.map((blogPost) => (
                  <BlogPostCard key={blogPost.slug} data={blogPost} />
                ))
              )}
            </BlogPostGrid>
            {searchKeyWord === '' && filters.length === 0 && (
              <Pagination context={pageContext} />
            )}
          </BlogSection>

          <TagSection>
            <SearchBarStack
              axis={'y'}
              justify={'space-between'}
              align={{ _: 'flex-start', sm: 'center' }}
              as={'form'}
              role={'search'}
              onSubmit={onSearchSubmit}
            >
              <SearchField
                value={searchKeyWord}
                setValue={setSearchKeyWord}
                placeholder={'Hae blogipostausta'}
                aria-label={'Hae blogikirjoituksista'}
              />

              <FilterWrapper>
                <legend className={'visually-hidden'}>
                  Suodata blogikirjoituksia
                </legend>

                <Text variant={'body'}>Avainsanat</Text>
                <FilterStack axis={'x'} spacing={'small'}>
                  {displayedFilters.map(({ name }: any) => (
                    <TogglableOption
                      key={name}
                      label={name}
                      selected={filters.includes(name.toLowerCase())}
                      onClick={() => toggleFilter(name.toLowerCase())}
                    >
                      <VisuallyHidden>Näytä kategorian</VisuallyHidden>
                      {name}
                      <VisuallyHidden> kirjoitukset</VisuallyHidden>
                    </TogglableOption>
                  ))}
                </FilterStack>
              </FilterWrapper>
            </SearchBarStack>
          </TagSection>
        </BlogContainer>
      </Background>
    </>
  );
};

export default Blog;

export const blogQuery = graphql`
  query BlogMainTemplateQuery($skip: Int!, $limit: Int!) {
    page: strapiBlogi {
      ...strapiBlogPageFragment
    }
    paginatedBlogs: allStrapiBlogPosts(
      sort: { order: DESC, fields: publishedAt }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...strapiBlogPostsFragment
      }
    }
    allBlogs: allStrapiBlogPosts(sort: { order: DESC, fields: publishedAt }) {
      nodes {
        ...strapiBlogPostsFragment
      }
    }
    allCategories: allStrapiBlogCategories {
      nodes {
        ...strapiBlogCategoriesFragment
      }
    }
  }
`;

const BlogContainer = styled(Content)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media ${devices.laptopM} {
    flex-direction: column-reverse;
    margin-top: -50px;
  }

  @media ${devices.mobileXL} {
    padding-bottom: 75px;
  }
`;

const BlogSection = styled.div`
  flex: 0 0 60%;
`;

const TagSection = styled.div`
  flex: 0 0 35%;
  padding-bottom: ${(props) => props.theme.spacing.xxlarge};

  @media ${devices.tablet} {
    padding-bottom: ${(props) => props.theme.spacing.large};
  }
`;

const SearchBarStack = styled(Stack)`
  padding: ${(p) => p.theme.spacing.small} ${(p) => p.theme.spacing.medium};
  border-radius: 5px;
  flex: 0 0 30%;

  @media ${devices.tablet} {
    flex: 0 0 60%;
  }
`;

const FilterWrapper = styled.fieldset`
  position: relative;
  flex: 1;
  height: 100%;

  @media ${devices.mobileXL} {
    max-width: 90%;
    flex-wrap: wrap;
    margin: auto;
  }
`;

const FilterStack = styled(Stack)`
  flex-wrap: wrap;
  width: 100%;
  min-width: min-content;
  padding: 8px 2px;

  > * {
    margin: 4px !important;
  }
`;

const BlogPostGrid = styled.div<{ isNotFirstPage: string }>`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: ${(p) => p.theme.spacing.default};
  width: 100%;
  margin-bottom: ${(p) => p.theme.spacing.large};

  > *:first-of-type {
    grid-column-start: ${(props) => !props.isNotFirstPage && '1'};
    grid-column-end: ${(props) => !props.isNotFirstPage && '3'};

    .imageWrapper {
      height: ${(props) => !props.isNotFirstPage && '400px'};
    }

    @media ${devices.mobileXL} {
      grid-column-end: ${(props) => !props.isNotFirstPage && '1'};
      height: auto;

      .imageWrapper {
        height: 200px;
      }
    }
  }

  @media ${devices.mobileXL} {
    grid-template-columns: none;
  }
`;

const Background = styled.div`
  background-color: ${(props) => props.theme.colors.lightBlue};
  padding-top: ${(props) => props.theme.spacing.xxlarge};
`;
