import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { MdTram as TramIcon } from 'react-icons/md';
import { IoMdBus as BusIcon, IoMdTrain as TrainIcon } from 'react-icons/io';
import { SEO, Content, Text, Spacing, devices } from '../components/common';
import { RichTextContent } from '../components/StyledComponents';
import { StrongUnderline, MarkerIcon } from '../components/SVG';
import HeroSection from '../components/HeroSection';
import ContactForm from '../components/Contact/ContactForm';
import GoogleMap from '../components/GoogleMaps';
import { ContactPage } from '../@types/types';
import theme from '../theme';
import ContactInfoSection from '../components/ContactInfoSection';

type Props = {
  data: { page: ContactPage };
  location: Location;
};

const ContactP = ({ data, location }: Props) => {
  const {
    page: {
      AboutOptions,
      Busses,
      City,
      Contact,
      ContactSectionContent,
      ContactSectionTitle,
      ContactFormContent,
      ContactFormTitle,
      MetaData,
      PageTopSection,
      PhoneNumber,
      PostalCode,
      StreetAddress,
      Trains,
      Trams,
      TravelSectionHeader,
      TravelSectionText,
    },
  } = data;

  return (
    <>
      <SEO
        pathname={location.pathname}
        title={MetaData.MetaTitle}
        description={MetaData.MetaDescription}
        keywords={MetaData.metaKeywords}
      />

      <HeroSection topSection={PageTopSection} />
      <CallUs id="callUs">
        <Text variant="title-2" color="white">
          Varaa aika soittamalla...
        </Text>
        <Spacing axis="y" amount="medium" />
        <a href="tel:+358103254540" style={{ textDecoration: 'none' }}>
          <Text variant="title-1" color="white">
            {PhoneNumber}
          </Text>
        </a>
        <StrongUnderline width={350} />
      </CallUs>
      <ContactSection id="contactForm">
        <ContentContainer>
          <Text variant="title-2" align="center">
            {ContactFormTitle}
          </Text>
          <Spacing axis="y" amount="medium" />
          <TextContent variant="body">{ContactFormContent}</TextContent>
          <Spacing axis="y" amount="xlarge" />
          <ContactForm about={AboutOptions.map((i) => i.Item)} />
        </ContentContainer>
      </ContactSection>

      <MapSectionContainer>
        <Content>
          <MapSection>
            <Directions>
              <DirectionsHeader variant="title-2">Sijainti</DirectionsHeader>
              <IconAndText>
                <MarkerIcon fill={theme.colors.darkBlue} />
                <Address>
                  {StreetAddress}
                  <br />
                  {`${PostalCode} ${City}`}
                </Address>
              </IconAndText>
              <IconAndText>
                <BusIcon size={36} />
                <Transportation>{`Bussit ${Busses}`}</Transportation>
              </IconAndText>
              <IconAndText>
                <TramIcon size={36} />
                <Transportation>{`Raitiolinjat ${Trams}`}</Transportation>
              </IconAndText>
              <IconAndText>
                <TrainIcon size={36} />
                <Transportation>{Trains}</Transportation>
              </IconAndText>

              <InstructionsContainer>
                {TravelSectionText && (
                  <>
                    <Text variant="title-4">{TravelSectionHeader}</Text>
                    <RichTextContent width="100%">
                      {TravelSectionText}
                    </RichTextContent>
                  </>
                )}
              </InstructionsContainer>
            </Directions>
            <Map>
              <GoogleMap />
            </Map>
          </MapSection>
        </Content>
      </MapSectionContainer>
      <ContactInfoSection
        contactTitle={ContactSectionTitle}
        contactContent={ContactSectionContent}
        contactEmployees={Contact}
      />
    </>
  );
};

export default ContactP;

export const serviceQuery = graphql`
  query ContactPageQuery {
    page: strapiYhteys {
      ...strapiContactFragment
    }
  }
`;

const CallUs = styled.div`
  background-color: ${(props) => props.theme.colors.darkBlue};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.theme.spacing.xxlarge};

  @media ${devices.mobileXL} {
    padding: ${(props) => props.theme.spacing.large};
  }
`;

const ContactSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.colors.lightBlue};
  padding: 100px 0;
`;

const MapSectionContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: calc(0.15 * 100vw - calc(0.0867 * 100vw)) 0px calc(0.12 * 100vw) 0px;

  @media ${devices.tablet} {
    padding: calc(0.15 * 100vw - calc(0.0867 * 100vw)) 0px calc(0.15 * 100vw)
      0px;
  }

  @media ${devices.mobileXL} {
    padding-bottom: 100px;
  }
`;

const MapSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: auto;
  padding: ${(props) => props.theme.spacing.large} 0px;

  @media ${devices.laptopXS} {
    align-items: normal;
    flex-direction: column;
  }
`;

const Directions = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 55%;
`;

const DirectionsHeader = styled(Text)`
  @media ${devices.mobileXL} {
    text-align: center;
    margin-bottom: ${(props) => props.theme.spacing.medium};
  }
`;

const IconAndText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${devices.mobileXL} {
    flex-direction: column;
    text-align: center;
  }
`;

const Address = styled.span`
  padding: ${(props) => props.theme.spacing.medium};
  font-size: 24px;
  font-weight: 700;
`;

const Transportation = styled.span`
  padding: ${(props) => props.theme.spacing.medium};
  font-size: 20px;
`;

const InstructionsContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${devices.laptop} {
    margin-bottom: ${(props) => props.theme.spacing.large};
  }
`;

const Map = styled.div`
  flex: 0 0 40%;
  height: 500px;
  border-radius: 30px;

  @media ${devices.mobileM} {
    height: 400px;
  }
`;

const TextContent = styled(Text)`
  margin: auto;
  word-break: normal;
  overflow-wrap: anywhere;
`;

const ContentContainer = styled(Content)`
  width: 625px;
  word-break: normal;
  overflow-wrap: anywhere;
`;
