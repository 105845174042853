import React from 'react';
import { SEO, Content, Text } from '../components/common';

const NotFoundPage = () => (
  <>
    <SEO title="404: Sivua ei löytynyt" pathname={'/404.html'} />

    <Content>
      <Text variant="title-1">404</Text>
      <Text variant="body">404 Not Found content here....</Text>
    </Content>
  </>
);

export default NotFoundPage;
