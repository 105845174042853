import React from 'react';
import ReactMarkdown from 'react-markdown/with-html';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { railscasts } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import styled from '@emotion/styled';
import { Text, Link, Stack } from './common';
import { Typography } from '../theme';
import { media } from '../utils/styled';

type Props = {
  blogCard?: boolean;
  modal?: boolean;
  children: any;
};

/* eslint-disable react/display-name */
const renderers: {
  [nodeType: string]: React.ElementType<any>;
} = {
  paragraph: ({ children }) =>
    // don't wrap pure img tags
    children[0]?.props?.src ? children : <Text variant="body">{children}</Text>,
  heading: ({ children, level }) => {
    let variant: Typography;
    if (level <= 3) {
      variant = `title-${Math.min(level, 3)}` as Typography;
    } else if (level === 4) {
      variant = 'subtitle';
    } else {
      variant = 'body';
    }

    return <Text variant={variant}>{children}</Text>;
  },
  link: ({ children, href, ...rest }) => (
    <Link {...rest} to={href} color="primary-dark">
      {children}
    </Link>
  ),
  code: ({ language, value }) => (
    <SyntaxHighlighter
      language={language}
      style={railscasts}
      customStyle={{
        fontSize: 14,
        padding: 20,
        borderRadius: 5,
        lineHeight: 1.5,
      }}
      wrapLines
    >
      {value}
    </SyntaxHighlighter>
  ),
  image: (props) => <Image {...props} />,
  listItem: (props) => <ListItem {...props} />,
  list: ({ children, ordered }) =>
    ordered ? (
      <OrderedList>{children}</OrderedList>
    ) : (
      <UnorderedList>{children}</UnorderedList>
    ),
  blockquote: (props) => <Blockquote {...props} />,
};

const Markdown: React.FC = (props: Props) => (
  <MarkdownStack blogCard={props.blogCard} modal={props.modal}>
    <ReactMarkdown escapeHtml={false} renderers={renderers}>
      {props.children}
    </ReactMarkdown>
  </MarkdownStack>
);

const MarkdownStack = styled(Stack)<{ blogCard?: boolean; modal?: boolean }>`
  width: 100%;
  max-width: 70ch;

  ${(p) =>
    p.blogCard &&
    // eslint-disable-next-line no-template-curly-in-string
    'overflow: hidden; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1; margin-top: ${(props) => props.theme.spacing.small}; text-overflow: ellipsis;'};

  h1 {
    font-size: ${(p) => p.blogCard && '18px'};
  }

  h2 {
    margin-top: ${(p) => p.theme.spacing.xxlarge} !important;
    font-size: ${(p) => p.blogCard && '18px'};
  }

  h3 {
    margin-top: ${(p) => p.theme.spacing.xlarge} !important;
    font-size: ${(p) => p.blogCard && '18px'};
  }

  h3 + p {
    margin-top: ${(p) => p.theme.spacing.default} !important;
  }

  h2 + h3 {
    margin-top: ${(p) => p.theme.spacing.medium} !important;
  }

  h4,
  h5,
  h6 {
    font-size: ${(p) => p.blogCard && '18px'};
  }

  ul {
    margin: ${(p) => p.modal && '0px !important'};
  }

  p + ul {
    & > li:first-of-type {
      margin-top: 0 !important;
    }

    & > li:last-of-type {
      margin-bottom: 0 !important;
    }
  }
`;

const ListItem = styled.li`
  margin: ${(p) => p.theme.spacing.small} 0;
  font-size: ${(p) => p.theme.sizing.default};
`;

const Image = styled.img`
  width: calc(100% + ${(p) => p.theme.spacing.xlarge});
  max-width: none;
  align-self: center;

  ${media.sm`
    width: 100vw;
  `}
`;

const OrderedList = styled.ol`
  padding-left: ${(p) => p.theme.spacing.large};
  list-style-type: decimal;
`;

const UnorderedList = styled.ul`
  padding-left: ${(p) => p.theme.spacing.large};
  list-style-type: disc;
`;

const Blockquote = styled.blockquote`
  background-color: ${(p) => p.theme.colors.lightBlue};
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: ${(p) => p.theme.spacing.medium};
  border-left: 8px solid ${(p) => p.theme.colors.darkBlue};
  border-radius: 8px;
  font-style: italic;
`;

export default Markdown;
