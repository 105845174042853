import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { SEO, Spacing } from '../components/common';
import ThreeSquareSection from '../components/ThreeSquareSection';
import { HomePage, CurrentShowcase, QuickHelp } from '../@types/types';
import { NextPage } from '../components/SVG';
import CurrentNews from '../components/CurrentNews';
import HelpGuide from '../components/HelpGuide';
import HomeHero from '../components/Home/Hero';
import type { ContactEmail } from '../@types/types';

type Props = {
  data: {
    page: HomePage;
    currentData: { nodes: CurrentShowcase[] };
    quickHelp: { nodes: QuickHelp[] };
    trainings: { nodes: CurrentShowcase[] };
    emails: ContactEmail[];
  };
  location: Location;
};

const Index = ({ data, location }: Props) => {
  const {
    page: { MetaData, ThreeBlockSection },
    currentData,
    trainings,
  } = data;

  const currentNews = () => {
    const current = currentData.nodes.map((element, index) => {
      return {
        ...currentData.nodes[index],
        slug: `/ajankohtaista/${currentData.nodes[index].slug}`,
      };
    });

    const trains = trainings.nodes.map((element, index) => {
      return {
        ...trainings.nodes[index],
        slug: `/koulutukset/${trainings.nodes[index].slug}`,
      };
    });

    return current.concat(trains).sort((a, b) => {
      return new Date(b.published_at) - new Date(a.published_at);
    });
  };

  console.log('trainings.nodes.length: ', trainings.nodes.length); // TODO: REMOVE

  return (
    <>
      <SEO
        pathname={location.pathname}
        title={MetaData.MetaTitle}
        description={MetaData.MetaDescription}
        keywords={MetaData.metaKeywords}
      />
      <HomeHero data={data} />
      {trainings && <CurrentNews current={currentNews()} />}
      <ThreeSquareSection
        content={ThreeBlockSection}
        colors={['white', 'white', 'white']}
        FirstButtons={
          <ReadMoreButton href={'/palvelut/'}>
            Lue lisää
            <Spacing axis="x" amount="medium" />
            <NextPage width="30" />
          </ReadMoreButton>
        }
        SecondButtons={
          <ReadMoreButton href={'/perhepalvelut/'}>
            Lue lisää
            <Spacing axis="x" amount="medium" />
            <NextPage width="30" />
          </ReadMoreButton>
        }
        ThirdButtons={
          <ReadMoreButton href={'/palvelut/psykoterapia'}>
            Lue lisää
            <Spacing axis="x" amount="medium" />
            <NextPage width="30" />
          </ReadMoreButton>
        }
      />
    </>
  );
};

export default Index;

export const homeQuery = graphql`
  query HomeQuery {
    page: strapiHome {
      ...strapiHomeFragment
    }
    currentData: allStrapiCurrent {
      nodes {
        ...strapiCurrentFragment
      }
    }
    trainings: allStrapiTrainings(filter: { Featured: { eq: true } }) {
      nodes {
        ...strapiTrainingsFragment
      }
    }
  }
`;

const ReadMoreButton = styled.a`
  color: ${(p) => p.theme.colors.darkBlue};
  background-color: ${(p) => p.theme.colors.white};
  border: 1px solid ${(p) => p.theme.colors.darkBlue};
  border-radius: 5px;
  padding: 0px 8px 0px 15px;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  transition: all 0.25s linear 0s;
  width: max-content;

  &:hover {
    background-color: #ebf5ff;
    transition: all 0.25s linear 0s;
  }
`;
