import React from 'react';
import styled from '@emotion/styled';
import { Search } from '../SVG';
import { devices } from '../common';

type Props = {
  value: string;
  setValue: (value: string) => void;
  placeholder?: string;
};

const SearchField: React.FC<Props> = ({
  value,
  setValue,
  placeholder,
  ...rest
}) => {
  return (
    <Container>
      <SearchFieldWrapper>
        <Input
          {...rest}
          id={'search-input'}
          value={value}
          onChange={(e) => setValue(e.currentTarget.value)}
          placeholder={placeholder}
          maxLength={20}
        />

        <MagnifyingGlass />
      </SearchFieldWrapper>
    </Container>
  );
};

export default SearchField;

const Container = styled.div`
  flex: 0;
  display: flex;
  align-items: center;
  width: 100%;

  @media ${devices.laptopM} {
    width: 50%;
  }

  @media ${devices.mobileXL} {
    width: 100%;
    margin-top: ${(props) => props.theme.spacing.medium};
  }
`;

const SearchFieldWrapper = styled.label`
  box-sizing: content-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 180px;
  width: 100%;
  padding: ${(props) => props.theme.spacing.default}
    ${(props) => props.theme.spacing.medium};
  border-radius: 32px;
  background-color: ${(props) => props.theme.colors.white};
  transition: background-color 0.15s, width 0.6s, min-width 0.6s;
  overflow: hidden;
  margin-bottom: ${(props) => props.theme.spacing.medium};
`;

const Input = styled.input`
  width: 100%;
  padding: 0;
  border: none;
  background-color: transparent;
  font: ${(props) => props.theme.typography.body};
  box-shadow: none;
`;

const MagnifyingGlass = styled(Search)`
  flex: none;
  width: 24px;
  cursor: pointer;
`;
