import { css } from '@emotion/react';
import { devices } from './components/common';

// TODO: update theme variables to match site design

const theme = {
  colors: {
    black: '#000000',
    white: '#FFFFFF',
    focus: '#003CFF',
    blue: '#7FADED',
    lightBlue: '#C4DEF9',
    lightGrey: '#EEE',
    grey: '#707070',
    selectedGrey: '#E1E1E1',
    selectedGreyDark: '#D4D4D4',
    darkBlue: '#0B2341',
    inActiveGrey: '#0000004D',
    mutedLightBlue: 'rgb(235, 245, 255)',
  },
  spacing: {
    none: '0px',
    xxsmall: '2px',
    xsmall: '4px',
    small: '8px',
    default: '16px',
    medium: '24px',
    large: '32px',
    xlarge: '48px',
    xxlarge: '64px',
    xxxlarge: '104px',
  },
  sizing: {
    small: '8px',
    default: '16px',
    medium: '24px',
    large: '32px',
    xlarge: '48px',
  },
  fonts: {
    primary: css`
      font-family: 'quatro', sans-serif;
    `,
    secondary: css`
      font-family: 'Sansita', sans-serif;
    `,
  },
  focus: css`
    box-shadow: 0 0 0 2px white, 0 0 0 5px #003cff;
  `,
  imageTransition: css`
    -webkit-transition: color 1s;
    -moz-transition: 1s;
    -o-transition: 1s;
    transition: 1s;
  `,
  typography: {
    'title-1': css`
      font-family: 'quatro', sans-serif;
      font-size: 56px;
      font-weight: 700;
      line-height: 1.2;

      @media ${devices.laptopM} {
        font-size: 52px;
      }

      @media ${devices.laptopXS} {
        font-size: 46px;
      }

      @media ${devices.tablet} {
        font-size: 40px;
      }

      @media ${devices.mobileXL} {
        font-size: 30px;
      }

      @media ${devices.mobileS} {
        font-size: 28px;
      }
    `,
    'title-2': css`
      font-family: 'quatro', sans-serif;
      font-size: 40px;
      font-weight: 700;
      line-height: 1.4;

      @media ${devices.tablet} {
        font-size: 36px;
      }

      @media ${devices.mobileL} {
        font-size: 28px;
      }
    `,
    'title-3': css`
      font-family: 'quatro', sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: 1.4;
    `,
    'title-4': css`
      font-family: 'quatro', sans-serif;
      font-size: 18px;
      font-weight: 700;
      line-height: 1.4;
    `,

    subtitle: css`
      font-family: 'quatro', sans-serif;
      font-size: 24px;
      line-height: 1.4;
      @media ${devices.tablet} {
        font-size: 20px;
      }
      @media ${devices.mobileL} {
        font-size: 18px;
      }
    `,
    body: css`
      font-family: 'quatro', sans-serif;
      font-size: 18px;
      line-height: 1.35;
      @media ${devices.tablet} {
        font-size: 16px;
      }
    `,
    'body-small': css`
      font-family: 'quatro', sans-serif;
      font-size: 16px;
      line-height: 1.35;
    `,
    'body-s-semi-bold': css`
      font-family: 'quatro', sans-serif;
      font-size: 16px;
      font-weight: 600;
    `,
    info: css`
      font-family: 'quatro', sans-serif;
      font-size: 14px;
    `,
  },
};

export type Theme = typeof theme;

export type Color = keyof Theme['colors'];

export type Spacing = keyof Theme['spacing'];

export type Sizing = keyof Theme['sizing'];

export type Typography = keyof Theme['typography'];

export default theme;
