import React, { useState, MouseEvent } from 'react';
import styled from '@emotion/styled';
import Snackbar from '@material-ui/core/Snackbar';
import { BlueButton, devices } from '../common';

type Props = {
  about?: string[];
};

const ContactForm = ({ about }: Props) => {
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [category, setCategory] = useState('');
  const [confirmMessageOpen, setConfirmMessageOpen] = useState(false);

  const handleSubmit = async (e: MouseEvent<HTMLFormElement>) => {
    e.preventDefault();

    // TODO check all TODOs below before putting this app to production
    // TODO put dev and prod urls here https://github.com/TaitoUnited/common-website/blob/dev/server/src/websites.json
    // TODO copy endpoint from https://github.com/TaitoUnited/common-website/blob/dev/server/src/forms/formsById-dev.json and add that to https://github.com/TaitoUnited/common-website/blob/dev/server/src/forms/formsById-prod.json
    // TODO add aatos clinic representatives' emails and delete @taitounited.fi -email(s) here https://github.com/TaitoUnited/common-website/blob/dev/server/src/forms/formsById-prod.json
    // TODO check that www.aatosklinikka.fi below is the right url

    try {
      // TODO: REPLACE THE VALUE FOR recipientEmail WITH THE VALUE FROM FAMILY CLINIC STRAPI
      const root =
        window.location.host === 'www.aatosklinikka.fi'
          ? 'https://common-website-prod.taitodev.com'
          : 'https://common-website-dev.taitodev.com';
      const formId = 'aatos-familyclinic-contact-qc2r-fe35h28km9z3';
      const fields = [
        { id: 'name', value: name, valueType: 'TEXT' },
        { id: 'phone number', value: phoneNumber, valueType: 'TEXT' },
        { id: 'category', value: category, valueType: 'TEXT' },
      ];

      const response = await fetch(`${root}/api/forms/${formId}/posts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: {
            fields,
          },
        }),
      });

      if (response.status === 200 || response.status === 201) {
        setName('');
        setPhoneNumber('');
        setCategory('');
        setConfirmMessageOpen(true);
      }
    } catch (error) {
      console.log('Form Error: ', error);
    }
  };

  const handleConfirmMessageClose = () => setConfirmMessageOpen(false);

  return (
    <>
      <Form method="POST" onSubmit={handleSubmit}>
        <Container>
          <InputGroup>
            <div>
              <Label>Nimi</Label>
              <Input
                type="text"
                name="name"
                onChange={(e) => setName(e.target.value)}
                spellCheck={false}
                value={name}
                placeholder="Nimi"
                required
              />
            </div>
            <div>
              <Label>Puhelinnumero</Label>
              <Input
                pattern="^[+ 0-9()-]+$"
                onChange={(e) => setPhoneNumber(e.target.value)}
                spellCheck={false}
                value={phoneNumber}
                required
                placeholder="Puhelinnumero"
              />
            </div>
          </InputGroup>
          {about && (
            <InputGroup>
              <div>
                <Label>Asiani koskee</Label>
                <Select
                  name="category"
                  onChange={(e) => setCategory(e.target.value)}
                  spellCheck={false}
                  value={category}
                  required
                  placeholder="Valitse"
                >
                  <Option disabled hidden value="">
                    Valitse
                  </Option>
                  {about.map((category, index) => {
                    return (
                      <Option key={index} value={category}>
                        {category}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </InputGroup>
          )}
        </Container>
        <SendButton type="submit" large={true}>
          Lähetä yhteydenottopyyntö
        </SendButton>
      </Form>
      <Snackbar
        open={confirmMessageOpen}
        autoHideDuration={5000}
        onClose={handleConfirmMessageClose}
      >
        <SnackbarContent>Yhteydenottopyynto lähetetty</SnackbarContent>
      </Snackbar>
    </>
  );
};

export default ContactForm;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${devices.laptopXS} {
    width: 100%;
  }
`;

const SnackbarContent = styled.div`
  background-color: ${(p) => p.theme.colors.blue};
  padding: 20px;
  border-radius: 8px;
  color: white;
  font-size: 18px;
  text-align: center;
`;

const SendButton = styled(BlueButton)`
  margin: 0;

  @media ${devices.mobileXL} {
    margin: auto;
  }
`;

const Label = styled.b`
  font-family: 'quatro';
  font-weight: 700;
  padding-bottom: 8px;
  display: block;
  margin-left: 5px;
`;

const Input = styled.input`
  display: block;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 12px 16px;
  width: 300px;
  font-size: 18px;
  margin: 0px 5px 36px 5px;
  box-shadow: none;

  @media ${devices.tablet} {
    width: 460px;
  }

  @media ${devices.mobileXL} {
    width: 100%;
  }
`;

const Select = styled.select`
  display: block;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 12px 16px;
  width: 460px;
  max-width: 100%;
  height: 50px;
  font-size: 18px;
  margin: 0px 5px 36px 5px;
  box-shadow: none;

  &:required:invalid {
    color: #ccc;
  }

  @media ${devices.mobileXL} {
    width: 100%;
  }
`;

const Option = styled.option`
  display: block;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 5px 10px;
  width: 460px;
  max-width: 100%;
  height: 50px;
  font-size: 18px;
  margin: 0px 5px 36px 5px;
  box-shadow: none;
  color: black;

  &:disabled {
    color: #808080;
  }

  @media ${devices.mobileXL} {
    width: 100%;
  }
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  @media ${devices.tablet} {
    flex-direction: column;
    width: 100%;
  }
`;
