import React from 'react';
import styled from '@emotion/styled';
import Text from './Text';
import { devices } from './MediaQueries';

type Props = {
  label: string;
  selected: boolean;
  onClick: () => void;
};

/** Inputs and labels need to be direct siblings instead of nested or else keyboard navigation
 * through different options is suboptimal
 */
const TogglableOption: React.FC<Props> = ({
  label,
  selected,
  onClick,
  children,
  ...rest
}) => (
  <TogglableOptionLabel
    htmlFor={label.replace(' ', '-')}
    selected={selected}
    {...rest}
  >
    <Text variant="body-s-semi-bold">#{children ?? label}</Text>
    <input
      className={'visually-hidden'}
      type={'checkbox'}
      name={label.replace(' ', '-')}
      id={label.replace(' ', '-')}
      checked={selected}
      onChange={onClick}
    />
  </TogglableOptionLabel>
);

const TogglableOptionLabel = styled.label<{ selected: boolean }>`
  border-radius: 24px;
  background-color: ${(p) =>
    p.theme.colors[p.selected ? 'selectedGrey' : 'white']};
  border: 1px solid
    ${(p) => p.theme.colors[p.selected ? 'selectedGrey' : 'lightGrey']};
  color: #000;
  padding: ${(p) => p.theme.spacing.small} ${(p) => p.theme.spacing.default};
  width: max-content;
  margin: ${(p) => p.theme.spacing.small};
  cursor: pointer;

  &:hover {
    border: 1px solid
      ${(p) => p.theme.colors[p.selected ? 'selectedGreyDark' : 'lightGrey']};
    background-color: ${(p) =>
      p.theme.colors[p.selected ? 'selectedGreyDark' : 'lightGrey']};
  }

  @media ${devices.mobileXL} {
    inline-size: 100%;
    width: 100%;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }
`;

export default TogglableOption;
