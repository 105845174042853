import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import styled from '@emotion/styled';
import { MarkerIcon } from './SVG';
import { devices } from './common';
import theme from '../theme';

class GoogleMap extends Component {
  static defaultProps = {
    center: {
      lat: 60.1972,
      lng: 24.9434,
    },
    zoom: 17,
  };

  render() {
    return (
      <Container>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyDXoCkp6H1OuQkv7igu6_XUjxLtmqu8-Mc' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <MarkerIcon
            fill={theme.colors.primaryBlue}
            width={'96'}
            lat={this.props.center.lat}
            lng={this.props.center.lng}
          />
        </GoogleMapReact>
      </Container>
    );
  }
}

export default GoogleMap;

const Marker = styled(MarkerIcon)`
  margin-top: -135px;
  margin-left: -65px;
`;

const Container = styled.div`
  height: 100%;
  width: 100%;

  & > div {
    width: 100% !important;
    height: 500px !important;

    @media ${devices.mobileM} {
      height: 400px !important;
    }
  }
  overflow: hidden;
  border-radius: 8px;
`;
