import React from 'react';
import styled from '@emotion/styled';
import { FaQuoteLeft as QuoteIcon } from 'react-icons/fa';

import { Text, devices } from './common';

type Props = {
  quotes: {
    Comment: string;
    employee: {
      Name: string;
      Title: string;
      Picture: {
        publicURL: string;
      };
    };
  }[];
};

const EmployeeQuotes = ({ quotes }: Props) => {
  return (
    <Container>
      {quotes.map((quote, index) => (
        <Quote key={index}>
          <EmployeeInfo>
            {/* {quote.employee?.Picture && (
              <ImageContainer>
                <Image
                  src={quote.employee.Picture.publicURL}
                  alt="Työntekijän kuva"
                />
              </ImageContainer>
            )} */}

            <InfoContainer>
              <Text variant="title-3">{quote.employee?.Name}</Text>
              <Text variant="body">{quote.employee?.Title}</Text>
            </InfoContainer>
          </EmployeeInfo>
          <TextContainer>
            <QuoteIcon />
            <Text variant="body">{quote.Comment}</Text>
          </TextContainer>
        </Quote>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-color: ${(p) => p.theme.colors.lightBlue};
  padding: 80px;

  @media ${devices.tablet} {
    grid-template-columns: 1fr;
    padding: ${(props) => props.theme.spacing.xxlarge}
      ${(props) => props.theme.spacing.medium};
  }
`;

const EmployeeInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: ${(props) => props.theme.spacing.default};
`;

const InfoContainer = styled.div`
  margin-left: 8px;
`;

const TextContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 20fr;

  @media ${devices.laptopM} {
    grid-template-columns: 1fr 9fr;
  }
`;

const ImageContainer = styled.div`
  height: 65px;
  width: 65px;
  min-height: 65px;
  min-width: 65px;
  margin-right: ${(props) => props.theme.spacing.default};

  @media ${devices.mobileXL} {
    margin-right: 0px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;

const Quote = styled.div`
  padding: 20px;

  @media ${devices.mobileXL} {
    padding: 32px 0px;
  }
`;

export default EmployeeQuotes;
