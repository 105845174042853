import styled from '@emotion/styled';
import { devices } from './common';
import Markdown from './Markdown';
import { Typography } from '../theme';

export const QuickHelpButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: max-content;
  flex-wrap: wrap;

  @media ${devices.mobileL} {
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    > * {
      margin: 5px;
    }
  }

  @media ${devices.tablet} {
    font-size: 64px;
    width: 100%;
    justify-content: center;
  }
`;

export const QuickHelpText = styled.h2`
  margin-right: ${(p) => p.theme.spacing.medium};
`;

export const QuickHelpTextContainer = styled.div`
  margin-bottom: ${(p) => p.theme.spacing.medium};
  text-align: center;
`;

export const RichTextContent = styled(Markdown)<{
  width?: string;
  quote?: boolean;
  largeText?: boolean;
  blog?: boolean;
  blogCard?: boolean;
  variant?: Typography;
}>`
  width: ${(props) => props.width && props.width};
  max-width: 100%;
  line-height: 24px;
  margin-top: ${(props) => props.quote && '-24px'};
  font-family: 'quatro', sans-serif;
  font-size: ${(props) => (props.largeText ? '20px' : '18px')};
  font-weight: ${(props) => props.largeText && '400'};
  margin: 24px 0px;
  ${(p) => p.variant && p.theme.typography[p.variant]}

  @media ${devices.mobileXL} {
    width: 100%;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }
`;
