import React from 'react';
import styled from '@emotion/styled';
import { useStaticQuery, graphql } from 'gatsby';
import { FaCalendarDay as Calendar } from 'react-icons/fa';
import { Text, Stack, Link, devices } from '../components/common';
import { PhoneIcon, EmailIcon } from './SVG';
import theme from '../theme';
import Spacer from './common/Spacer';

type HelpGuide = {
  type: string;
  icon: JSX.Element;
  title: string;
  link: string;
  phoneNumber: string;
};

const HelpGuide = () => {
  const { helpGuide } = useStaticQuery(
    graphql`
      query {
        helpGuide: allStrapiHelpGuide {
          nodes {
            title
            type
            phoneNumber
            link
          }
        }
      }
    `
  );

  const contactNodes = helpGuide.nodes.filter(
    (item: { type: string }) => item.type === 'contact'
  );
  const firstContactNode = contactNodes.slice(0, 1)[0];
  const primaryPhoneNodes = helpGuide.nodes.filter(
    (item: { type: string }) => item.type === 'primaryCall'
  );
  const firstPrimaryPhoneNode = primaryPhoneNodes.slice(0, 1)[0];

  return (
    <Container axis="y" spacing="default">
      <Grid>
        {helpGuide.nodes.map((item: HelpGuide) => {
          if (item.type === 'phoneCall') {
            return (
              <LinkCard href={`tel:${item.phoneNumber}`}>
                <SubContainer axis="x" spacing="none">
                  <CardTitle variant="body" color="darkBlue" align="center">
                    <PhoneIcon fill={theme.colors.darkBlue} width="18px" />
                    &nbsp;
                    {item.title}
                  </CardTitle>
                </SubContainer>
              </LinkCard>
            );
          } else if (item.type === 'online') {
            return (
              <LinkCard href={item.link} target="_blank">
                <CardTitle variant="body" color="darkBlue" align="center">
                  <Calendar size={16} color={theme.colors.darkBlue} />
                  &nbsp;
                  {item.title}
                </CardTitle>
              </LinkCard>
            );
          }
        })}
      </Grid>
      <Spacer axis="y" amount="xlarge" />
      <Title variant="title-3" color="darkBlue" align="left">
        Autamme palvelun valinnassa
      </Title>
      <BottomNav>
        <NavCard to={firstContactNode.link}>
          <Stack axis="x" spacing="small" align="center">
            <EmailIcon fill={theme.colors.white} />
            <CardTitle variant="body" color="white">
              {firstContactNode.title}
            </CardTitle>
          </Stack>
        </NavCard>
        <Spacer axis="x" amount="default" />
        <Spacer axis="y" amount="default" />
        <Text variant="body" color="darkBlue">
          tai
        </Text>
        <Spacer axis="x" amount="default" />
        <Spacer axis="y" amount="default" />
        <LinkCardBottom
          href={`tel:${firstPrimaryPhoneNode.phoneNumber}`}
          background={theme.colors.darkBlue}
          bottom
        >
          <Stack axis="x" spacing="small" align="center">
            <PhoneIcon fill={theme.colors.white} />
            <CardTitle variant="body" color="white">
              {firstPrimaryPhoneNode.title}
            </CardTitle>
          </Stack>
        </LinkCardBottom>
      </BottomNav>
    </Container>
  );
};

export default HelpGuide;

const Container = styled(Stack)`
  width: 100%;
  height: auto;
  margin: 50px auto;
  border-radius: ${(props) => props.theme.sizing.small};

  @media ${devices.mobileXL} {
    width: 95%;
  }
`;

const LinkCard = styled.a<{ background?: string; bottom?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  border-radius: ${(props) => props.theme.sizing.small};
  background-color: ${(props) => props.background || props.theme.colors.white};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-decoration: none;
  flex: 1;
  align-items: center;
  justify-content: center;

  @media ${devices.mobileXL} {
    flex-direction: column;
  }
`;
const LinkCardBottom = styled.a<{ background?: string; bottom?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  border-radius: ${(props) => props.theme.sizing.small};
  background-color: ${(props) => props.background || props.theme.colors.white};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-decoration: none;
  align-items: center;
  justify-content: center;

  @media ${devices.mobileXL} {
    flex-direction: column;
  }
`;

const NavCard = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  border-radius: ${(props) => props.theme.sizing.small};
  background-color: ${(props) => props.theme.colors.darkBlue};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-decoration: none;

  @media ${devices.mobileS} {
    width: 95%;
    padding: ${(props) => props.theme.spacing.default};
  }
`;

const Title = styled(Text)`
  font-weight: 500;

  @media ${devices.mobileXL} {
    text-align: center;
  }
`;

const BottomNav = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${devices.mobileXL} {
    flex-direction: column;
  }
`;

const SubContainer = styled(Stack)`
  @media ${devices.mobileXL} {
    flex-direction: column;
    align-items: center;
  }
`;

const CardTitle = styled(Text)`
  font-weight: 500;

  @media ${devices.mobileXL} {
    text-align: center;
    ${(props) => props.theme.typography['body-small']}
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${(props) => props.theme.sizing.default};
  grid-auto-rows: 1fr
  align-items: start;

  @media ${devices.tablet} {
    grid-template-columns: repeat(1, 1fr);
  }
`;
