export const MAX_CONTENT_WIDTH = '1300px';
export const MAX_TEXT_WIDTH = '70ch'; // Use character length to ensure optimal reading length
export const IS_BROWSER = typeof window !== 'undefined';

export const BREAKPOINTS = {
  sm: { min: 0, max: 767 },
  md: { min: 768, max: 1023 },
  lg: { min: 1024, max: 1279 },
  xl: { min: 1280, max: Infinity },
};

type ExtraBreakpoints = 'mdDown' | 'mdUp' | 'lgDown' | 'lgUp';
export type BreakpointKey = keyof typeof BREAKPOINTS | ExtraBreakpoints;
